import { Component, Prop } from "vue-property-decorator";
import CardModal from "@/components/CardModal.vue";
import TreeView from "@/components/TreeView.vue";
import FormBase from "@/views/FormBase/FormBase.vue";
import FormTable from "@/components/vue/FormTable.vue";
import DataTable from "@/components/vue/DataTable.vue";
import { IColumn, IRow } from "@/components/vue/form-table/FormFields";
import { createYearSelection, DayType, SelectOption, SupplyStagePeriodType } from "@/models/enums";
import { SupplyStage } from "@/models";
import { Decimal } from "decimal.js";
import { ModalResult, ModalVue } from "@/view-models/ModalRequest";
import { ISupplyStageModalArgs } from "../ModalArgs";
import { Address, formatAddress } from "@/models/ComposedLots/specifications";
import EventBus, { showModal } from "@/EventBus";
import { PickAddressModal } from "@/modal-views/PickAddressModal";
import { applyN } from "@/models/parsing";

interface ISupplyStageSelection {
    volume: Decimal | null
    type: SupplyStagePeriodType
    relativePeriod: {
        startOffset: number | null
        startOffsetType: DayType
        endOffset: number | null
        endOffsetType: DayType
        finishYear: number | null
    }
    absolutePeriod: {
        startDate: Date | null
        endDate: Date | null
        fromContractConclusion: boolean
    }
    conditions: string
    address: Address | null
}

@Component({ components: { CardModal, FormBase, TreeView, FormTable, DataTable } })
export default class PurchaseObjectDetailedAddStageModal extends ModalVue<ISupplyStageModalArgs, SupplyStage> {
    @Prop() private prop!: ISupplyStageModalArgs;

    private get stage() {
        return this.prop.stage;
    }

    private get availableVolume() {
        return this.prop.volume;
    }

    public get addressDisplayValue() {
        return this.selection.address ? applyN(formatAddress, this.selection.address) : "Не указан";
    }

    public selection: ISupplyStageSelection = {
        volume: this.stage ? this.stage.volume : this.availableVolume,
        type: this.stage ? this.stage.type : this.prop.requiredPeriodType || SupplyStagePeriodType.RELATIVE,
        relativePeriod: {
            startOffset: this.stage ? this.stage.relativePeriod.startOffset : null,
            startOffsetType: this.stage ? this.stage.relativePeriod.startOffsetType : DayType.WORKDAY,
            endOffset: this.stage ? this.stage.relativePeriod.endOffset : null,
            endOffsetType: this.stage ? this.stage.relativePeriod.endOffsetType : DayType.WORKDAY,
            finishYear: this.stage ? this.stage.relativePeriod.finishYear : new Date().getFullYear(),
        },
        absolutePeriod: {
            startDate: this.stage ? this.stage.absolutePeriod.startDate : null,
            endDate: this.stage ? this.stage.absolutePeriod.endDate : null,
            fromContractConclusion: this.stage ? this.stage.absolutePeriod.fromContractConclusion : false
        },
        conditions: this.stage ? this.stage.conditions : "в соответствии с проектом Контракта и Техническим заданием",
        address: this.stage ? this.stage.address : null,
    };

    public selectAddress = async () => {
        const result = await showModal(PickAddressModal, { existing: this.stage?.address ? { ...this.stage.address } : undefined });
        if (result) {
            this.selection.address = result;
        }
    };

    public formColumns: IColumn<ISupplyStageSelection>[] = [
        {
            rows: [
                {
                    title: "Объём поставки",
                    type: "DECIMAL",
                    style: "SHORT",
                    visible: () => !this.prop?.purchasePerUnit,
                    getter(src) {
                        return src.volume?.lt(0) ? new Decimal(0) : src.volume;
                    },
                    setter(src, value: Decimal) {
                        src.volume = value;
                    },
                },
            ],
        },
    ];

    public relative: IRow<ISupplyStageSelection>[] = [
        {
            title: "Начало поставки",
            type: "INTEGER",
            fieldDescription: "дней с момента заключения контракта",

            getter(src) {
                return src.relativePeriod.startOffset;
            },
            setter(src, value: number) {
                src.relativePeriod.startOffset = value;
            },
        },
        {
            title: "",
            type: "INLINE_RADIO_SELECT",
            groupName: "startDayType",

            selectOptions: [
                { key: DayType.WORKDAY, desc: "Рабочие дни" },
                { key: DayType.NORMAL, desc: "Календарные дни" },
            ],

            getter(src) {
                return src.relativePeriod.startOffsetType;
            },
            setter(src, value: DayType) {
                src.relativePeriod.startOffsetType = value;
            },
        },
        {
            title: "Окончание поставки",
            type: "INTEGER",
            fieldDescription: "дней с момента заключения контракта",

            getter(src) {
                return src.relativePeriod.endOffset;
            },
            setter(src, value: number) {
                src.relativePeriod.endOffset = value;
            },
        },
        {
            title: "",
            type: "INLINE_RADIO_SELECT",
            groupName: "endDayType",

            selectOptions: [
                { key: DayType.WORKDAY, desc: "Рабочие дни" },
                { key: DayType.NORMAL, desc: "Календарные дни" },
            ],

            getter(src) {
                return src.relativePeriod.endOffsetType;
            },
            setter(src, value: DayType) {
                src.relativePeriod.endOffsetType = value;
            },
        },
        {
            title: "Год окончания поставки",
            type: "SELECT",

            selectOptions: createYearSelection(true, new Date().getFullYear(), ""),

            getter(src) {
                return src.relativePeriod.finishYear;
            }, // todo
            setter(src, value: string) {
                src.relativePeriod.finishYear = value ? Number(value) : new Date().getFullYear();
            },
        },
    ];

    get startDateRow(): IRow<ISupplyStageSelection> {
        return  {
            title: "Дата начала поставки",
            type: "DATE_FIELD",
            style: "SHORT",

            getter(src: ISupplyStageSelection) {
                return src.absolutePeriod.startDate;
            },
            setter(src: ISupplyStageSelection, value: Date) {
                src.absolutePeriod.startDate = value;
            },
        };
    }

    get absolute(): IRow<ISupplyStageSelection>[] {
        //@ts-expect-error col.title don't exist on IRow RAW. It's fine because absolute period doesn't have those columns
        return this.selection.absolutePeriod.fromContractConclusion ? this.absoluteTemplate.filter(col => col.title !== "Дата начала поставки") : this.absoluteTemplate;
    }

    get fromContractConclusion(): IRow<ISupplyStageSelection>[] {
        return this.fromContractConclusionTemplate;
    }

    public fromContractConclusionTemplate: IRow<ISupplyStageSelection>[] = [
        {
            title: "",
            type: "CHECKBOX",
            fieldDescription: "С даты заключения контракта",
            visible(src) { return src.type === SupplyStagePeriodType.ABSOLUTE},
            getter(src) {
                return src.absolutePeriod.fromContractConclusion;
            },
            setter(src, value) {
                src.absolutePeriod.fromContractConclusion = value;
                if(value) {
                    // src.absolutePeriod.startDate = contract.conclusionDate
                } else {
                    src.absolutePeriod.startDate = null;
                }
            }
        }
    ];

    public absoluteTemplate: IRow<ISupplyStageSelection>[] = [
        {
            title: "Дата начала поставки",
            type: "DATE_FIELD",
            style: "SHORT",

            getter(src: ISupplyStageSelection) {
                return src.absolutePeriod.startDate;
            },
            setter(src: ISupplyStageSelection, value: Date) {
                src.absolutePeriod.startDate = value;
            },
        },
        {
            title: "Дата окончания поставки",
            type: "DATE_FIELD",
            style: "SHORT",

            getter(src) {
                return src.absolutePeriod.endDate;
            },
            setter(src, value: Date) {
                src.absolutePeriod.endDate = value;
            },
        },
    ];

    public conditions: IRow<ISupplyStageSelection>[] = [
        {
            title: "Условия поставки",
            type: "TEXT",

            getter(src) {
                return src.conditions;
            },
            setter(src, value: string) {
                src.conditions = value;
            },
        },
    ];

    public selectItemAndExit() {
        if (this.isOk) {
            if (this.prop.purchasePerUnit) {
                this.selection.volume = new Decimal(1);
            }

            const result = Object.assign((!this.prop.immutable ? this.stage : undefined) || Object.create(SupplyStage.prototype), this.selection);
            this.$emit("close-modal", ModalResult.ok(result));
        }
    }

    public selectSupplyTermsGroup(v: SupplyStagePeriodType) {
        this.selection.type = v;
        switch (v) {
            case SupplyStagePeriodType.ABSOLUTE:
                this.selection.relativePeriod.startOffset = null;
                this.selection.relativePeriod.startOffsetType = DayType.WORKDAY;
                this.selection.relativePeriod.endOffset = null;
                this.selection.relativePeriod.endOffsetType = DayType.WORKDAY;
                this.selection.relativePeriod.finishYear = null;
                break;
            case SupplyStagePeriodType.RELATIVE:
                this.selection.absolutePeriod.fromContractConclusion = false;
                this.selection.absolutePeriod.endDate = null;
                this.selection.absolutePeriod.startDate = null;
                break;
        }
    }

    public get isOk(): boolean {
        const baseConditions = (this.prop?.purchasePerUnit || !!this.selection.volume && this.selection.volume.gt(0)) && !!this.selection.conditions && this.selection.conditions.trim().length > 0;
        if(this.selection.type === SupplyStagePeriodType.RELATIVE) {
            return baseConditions && this.selection.relativePeriod.endOffset !== null &&
            this.selection.relativePeriod.endOffset > 0 &&
            this.selection.relativePeriod.startOffset !== null &&
            this.selection.relativePeriod.startOffset >= 0 &&
            this.selection.relativePeriod.endOffset >= this.selection.relativePeriod.startOffset &&
            this.selection.relativePeriod.finishYear !== null;
        }

        if(this.selection.absolutePeriod.fromContractConclusion) {
            return baseConditions && this.selection.absolutePeriod.endDate !== null && +this.selection.absolutePeriod.endDate >= +(this.selection.absolutePeriod.startDate ?? new Date());
        }

        return baseConditions && this.selection.absolutePeriod.startDate !== null &&
               this.selection.absolutePeriod.endDate !== null &&
               +this.selection.absolutePeriod.endDate >= +this.selection.absolutePeriod.startDate;
    }
}
