













































import FinancialSources from "@/api/FinancialSources";
import { FilterConditionType, FilterValueType } from "@/api/http";
import { createPaginationContext, PaginationContext } from "@/api/Pagination";
import CardModal from "@/components/CardModal.vue";
import { IColumn } from "@/components/vue/form-table/FormFields";
import FormTable from "@/components/vue/FormTable.vue";
import { ITableColumn, SingleSelection } from "@/components/TableAbstractions";
import TreeView from "@/components/TreeView.vue";
import { FinancialActionSource, FinancialSource } from "@/models";
import { createYearSelection, FinancialSourceType, LawType } from "@/models/enums";

import { formatNumber } from "@/NumberFormatting";

import { ModalResult } from "@/view-models/ModalRequest";
import { Decimal } from "decimal.js";
import { Vue, Component, Prop } from "vue-property-decorator";
import { makeFormHelpers } from "@/form-declarations/helpers";
import { ContractParticipantAccount, ContractFinancialSource } from "@/models/Contracts";
import { BankAccount } from '@/models/BankAccount';

import ContractFinancialSources from '@/api/ContractFinancialSources';
import { getCurrentYear } from '@/dateUtils';
import { FinancialLimit } from "@/models/ComposedLots/FinancialLimit";
import {LotPlannedPayment} from "@/models/ComposedLots/LotPlannedPayment";

interface ISourceSelection {
    type: FinancialSourceType
    amount: Decimal
    year: number
    account: BankAccount | null
    selectedSource: SingleSelection<FinancialSource | FinancialLimit>
    existingSelection: ContractFinancialSource | null
}

const h = makeFormHelpers<ISourceSelection>();


// used only for creation
const convertLotPlannedPaymentToContractFinancialSource = (v: LotPlannedPayment, selection: ISourceSelection): ContractFinancialSource => {
    const limit = selection.selectedSource.item as unknown as FinancialLimit | null;
    if (!limit) throw "nothing is selected";
    const source = new FinancialActionSource(
            limit?.id!,
            limit?.type! as FinancialSourceType,
            limit?.price ?? new Decimal(0),
            limit?.plannedAmount ?? new Decimal(0),
            limit?.year ?? new Date().getFullYear(),
            "",
            limit?.kvr ?? "",
            limit?.kosgu ?? "",
            limit?.kvfo ?? "",
            limit?.comment ?? "",
            limit?.is223 ? "F223" : "F44",
    );
    const result = new ContractFinancialSource(
        null!,
        source,
        selection.amount,
        selection.account
    );

    return result;
};

// "default" - используем существующие источники финансирования
// "inherited" - берем FinancialLimit из лота
export type AddSourceModalSourcePropType = { type: "default"; value: ContractFinancialSource } | { type: "inherited"; value: LotPlannedPayment[] } | { type: "empty"; value: null };

@Component({ components: { CardModal, TreeView, FormTable } })
export default class AddSource extends Vue {
    @Prop() private prop!: { contractId: string; source: AddSourceModalSourcePropType; law: LawType; accounts: ContractParticipantAccount[]; isAggregated: boolean | null };

    public get showLimitsInsteadOfActionSources() {
        if (!this.prop.isAggregated) return false;
        if (this.prop.source.type === "inherited") return true;
        return false;
    }

    private createSelection(): ISourceSelection {
        let year = getCurrentYear();
        let type = FinancialSourceType.FIN_ACTIONS;

        const reload = () => {
            if (this.context === null) return;

            this.context.filter = {
                year: [{
                    type: FilterValueType.LONGINT,
                    conditionType: FilterConditionType.EQUAL,
                    longint: year,
                }],
                law: [{
                    type: FilterValueType.STRING,
                    conditionType: FilterConditionType.EQUAL,
                    string: this.lawType,
                }]
            };

            this.context.load(0);
        };

        return {
            get type() { return type },
            set type(t) {
                if (type !== t) {
                    type = t;
                    reload();
                }
            },
            amount: new Decimal(0),
            get year() { return year },
            set year(y) {
                if (year !== y) {
                    year = y;
                    reload();
                }
            },
            selectedSource: { tag: "SINGLE", item: null },
            account: null,
            existingSelection: null,
        };
    }

    private readonly selection: ISourceSelection = this.createSelection();

    public headersForInherited: ITableColumn<FinancialLimit>[] = [
      {
        title: "КВР",
        getter(row) {
          return row.kvr;
        },
      },
      {
        title: "КОСГУ",
        getter(row) {
          return row.kosgu;
        },
      },
      {
        title: "КВФО",
        getter(row) {
          return row.kvfo;
        },
      },
      {
        title: "Сумма лимита",
        getter(row) {
          return formatNumber(row.price);
        },
      },
      {
        title: "Запланировано",
        getter(row) {
          return formatNumber(row.plannedAmount);
        },
      },
      {
        title: "Остаток",
        getter(row) {
          return formatNumber(row.price.sub(row.plannedAmount));
        },
      },
    ];

    public get headers() {
        switch (this.selection.type) {
            case FinancialSourceType.FIN_ACTIONS:
                return this.finActionsHeaders;
            default:
                return this.finActionsHeaders; // lul
        }
    }

    private finActionsHeaders: ITableColumn<FinancialActionSource>[] = [
        {
            title: "КВР",
            getter(row) {
                return  row.expenseKind;
            },
        },
        {
            title: "КОСГУ",
            getter(row) {
                return row.govOperationClass;
            },
        },
        {
            title: "КВФО",
            getter(row) {
                return row.provisionCode;
            },
        },
        {
            title: "Сумма лимита",
            getter(row) {
                return formatNumber(row.total);
            },
        },
        {
            title: "Запланировано",
            getter(row) {
                return formatNumber(row.used);
            },
        },
        {
            title: "Остаток",
            getter(row) {
                return formatNumber(row.total.sub(row.used));
            },
        },
    ];

    public formColumns: IColumn<ISourceSelection>[] = [
        {
            rows: [
                {
                    title: "Источник финансирования",
                    required: true,
                    type: "RADIO_SELECT",
                    selectOptions: [
                        { key: FinancialSourceType.LIMIT, desc: "Бюджет города Москвы", disabled: true },
                        { key: "?", desc: "Федеральные средства", disabled: true },
                        { key: FinancialSourceType.FIN_ACTIONS, desc: "ПФХД" },
                        { key: FinancialSourceType.OWN_FUNDS, desc: "Собственные средства", disabled: true },
                    ],
                    groupName: "financialSourceType",

                    getter(src) {
                        return src.type;
                    },
                    setter(src, type: FinancialSourceType) {
                        src.type = type;
                    },
                },
            ],
        },
        {
            rows: [
                h
                    .required()
                    .explicit()
                    .select("Год", () => "", () => {}, createYearSelection(false, new Date().getFullYear())),
            ],
        },
        {
            rows: [
                h
                    .required()
                    .decimal("​​Сумма, руб.", "amount"),
                h
                    .required()
                    .select("Лицевой счёт", "account", this.prop.accounts.filter(x => x.selected).map(x => ({ key: x.account, desc: x.account.accountNumber }))),
                h
                    .explicit()
                    .text("Номер титула", () => "", () => {}),
            ],
        },
        {
            rows: [
                h
                    .required()
                    .const()
                    .explicit()
                    .text("Текущий источник", src => {
                            if(src.selectedSource.item && "format" in src.selectedSource.item) return src.selectedSource.item.format(true);
                            if(src.selectedSource.item && src.selectedSource.item.kvr && src.selectedSource.item.kosgu) return `Код: ${src.selectedSource.item.kvr}-${src.selectedSource.item.kosgu}`;
                            return "";
                        }, () => {}),
            ],
        },
    ];

    private lawType!: LawType;
    public context: PaginationContext<FinancialSource> | null = null;

    public async mounted() {
        if (this.prop.source.type === "default") {
            this.selection.type = this.prop.source.value.source.type;
            this.selection.amount = this.prop.source.value.amount;
            this.selection.selectedSource = { tag: "SINGLE", item: this.prop.source.value.source };
            this.selection.existingSelection = this.prop.source.value;
            const accountId = this.prop.source.value.account && this.prop.source.value.account.id;
            const participantAccount = this.prop.accounts.find(x => !!x.account && x.account.id === accountId);
            const firstAcc = this.prop.accounts.filter(x => x.selected)[0];
            this.selection.account =
                (participantAccount && participantAccount.account) ||
                this.prop.source.value.account ||
                (firstAcc && firstAcc.account);
            this.selection.year = this.prop.source.value.source.year;
        } else if (this.prop.source.type === "empty") {
            const firstAcc = this.prop.accounts.filter(x => x.selected)[0];
            if (firstAcc) this.selection.account = firstAcc.account;
        } else if (this.prop.source.type === "inherited") {
            // ???
            /*
            lot financial source types:
            | "FIN_ACTIONS"
            | "OWN_FUNDS"
            | "OWN_FUNDS_SUB"
            | "LIMIT"
            | "FEDERAL_FUNDS";

            contract financial source types:
            | "FIN_ACTIONS"
            | "OWN_FUNDS"
            | "LIMIT";
             */
            // TODO: make sure this financial source is compatible with src/models/enums/FinancialSourceType.ts
            this.selection.type = this.prop.source.value[0].source.type as FinancialSourceType;
            this.selection.amount = new Decimal(0);
            this.selection.selectedSource = { tag: "SINGLE", item: this.prop.source.value[0].source };
            this.selection.existingSelection = null;
            const firstAcc = this.prop.accounts.filter(x => x.selected)[0];
            this.selection.account = firstAcc && firstAcc.account;
            this.selection.year = this.prop.source.value[0].source.year;
        }

        this.lawType = this.prop.law;

        this.context = await createPaginationContext(
            (n, sz, f) => FinancialSources.get(n, sz, f), 10, {
                year: [{
                    type: FilterValueType.LONGINT,
                    conditionType: FilterConditionType.EQUAL,
                    longint: this.selection.year,
                }],
                law: [{
                    type: FilterValueType.STRING,
                    conditionType: FilterConditionType.EQUAL,
                    string: this.lawType,
                }]
            }
        );
    }

    public loading = false;

    public async selectItemAndExit() {
        if (!this.isOk) return;

        let sel: ContractFinancialSource;
        if (this.prop.source.type === "inherited") {
            const plannedPayment = this.prop.source.value.find(i => i.source.id === this.selection.selectedSource.item?.id)!;
            sel = convertLotPlannedPaymentToContractFinancialSource(plannedPayment, this.selection);
        } else if (this.prop.source.type === "default") {
            sel = new ContractFinancialSource(
                this.prop.source.value.id,
                this.selection.selectedSource.item! as FinancialActionSource,
                this.selection.amount,
                this.selection.account);
        } else {
            sel = new ContractFinancialSource(
                null!, // TODO: nullable field (it really is)
                this.selection.selectedSource.item! as FinancialActionSource,
                this.selection.amount,
                this.selection.account
            );
        }
        this.loading = true;

        try {
            let src: ContractFinancialSource;
            if (this.prop.source.type === "empty" || this.prop.source.type === "inherited") {
                src = await ContractFinancialSources.create(this.prop.contractId, sel);
            } else {
                src = await ContractFinancialSources.update(sel);
            }
            this.$emit("close-modal", ModalResult.ok(src));
        } finally {
            this.loading = false;
        }
    }
    private get aggregatedDataset(): FinancialActionSource[] {
        if (!this.prop.isAggregated) return [];
        if (this.prop.source.type === "default") {
            return [this.prop.source.value.source];
        }
        return [];
    }
    private get inheritedDataset(): FinancialLimit[] {
        if (this.prop.source.type === "inherited") {
            return this.prop.source.value.map(v => v.source);
        }
        return [];
    }
    private get isOk(): boolean {
        return this.selection.amount.gt(0) && this.selection.selectedSource.item !== null && !!this.selection.account;
    }
}
