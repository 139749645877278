import { AutoDto, AutoModel, MDMap } from "@/models/parsing";
import Decimal from "decimal.js";

export interface ContractLifecycleConditionsMD {
    reasons: string[]
    executionCost: Decimal
    serviceCost: Decimal
}

export type DtoContractLifecycleConditions = AutoDto<ContractLifecycleConditionsMD> | null;
export type ContractLifecycleConditions = AutoModel<ContractLifecycleConditionsMD> | null;

export const ContractLifecycleConditions = {
    toDto(info: ContractLifecycleConditions): DtoContractLifecycleConditions {
        if(!info || !info.reasons.length) return null;
        return {
            reasons: info.reasons,
            executionCost: info.executionCost?.toString(),
            serviceCost: info.serviceCost?.toString(),
        };
    },
    fromDto(info: DtoContractLifecycleConditions): ContractLifecycleConditions {
        if(!info) return null;
        return {
            reasons: info.reasons,
            executionCost: new Decimal(info.executionCost),
            serviceCost: new Decimal(info.serviceCost),
        };
    }
};