import React, {useState} from "react";
import {ModalFC} from "@/EventBus";
import {createYearSelection, FinancialSourceType, LawType} from "@/models/enums";
import {Alternative} from "@/typeUtils";
import { convertFromFinancialSource, LotPlannedPayment } from "@/models/ComposedLots/LotPlannedPayment";
import {FinancialLimit} from "@/models/ComposedLots/FinancialLimit";
import {CardModal} from "@/components/modals/CardModal";
import {Expandee, VStack, HStack, VGrid} from "@/components/layouts";
import {Button, ComboBox, DecimalBox, Label, LabelPresets, Radio, RadioGroup} from "@/components/primitive";
import {
    createStoredRemoteDataset,
    Pager,
    SelectionTable,
    setFilterValue, Table,
    useStoredRemoteDataset
} from "@/components/table";
import {Decimal} from "decimal.js";
import FinancialSources from "@/api/FinancialSources";
import {FilterConditionType, FilterValueType} from "@/api/http";
import {Field, FieldStack} from "@/components/form/Field";
import {formatNumber} from "@/NumberFormatting";
import {FinancialActionSource, FinancialSource} from "@/models";

type PickFinancialSourceProps = Alternative<"existingSource", LotPlannedPayment, "law", LawType>;

type Filters = {
    law: LawType
    type: FinancialSourceType
    year: number
};

const yearOptions = createYearSelection(false, new Date().getFullYear());

export const PickFinancialSourceModal: ModalFC<PickFinancialSourceProps, LotPlannedPayment> = x => {
    const law = x.law ?? (x.existingSource.source.is223 ? "F223" : "F44");

    const [type, setTypeRaw] = useState<FinancialSourceType>(FinancialSourceType.FIN_ACTIONS);
    const [year, setYearRaw] = useState(new Date().getFullYear());
    const [amount, setAmount] = useState<Decimal | undefined>(new Decimal(0));

    const [remoteDatasetStore] = useState(() => createStoredRemoteDataset<FinancialLimit, Filters>({
        pageSize: 10,
        initialFilters: { law, type, year },
        async load(filters, from, count) {
            const result = await FinancialSources.get(from, count, {
                year: [{
                    type: FilterValueType.LONGINT,
                    conditionType: FilterConditionType.EQUAL,
                    longint: filters.year,
                }],
                law: [{
                    type: FilterValueType.STRING,
                    conditionType: FilterConditionType.EQUAL,
                    string: filters.law,
                }],
                type: [{
                    type: FilterValueType.STRING,
                    conditionType: FilterConditionType.EQUAL,
                    string: filters.type,
                }]
            });

            return {
                ...result,
                items: result.items.map(convertFromFinancialSource)
            };
        }
    }));

    const remoteDataset = useStoredRemoteDataset(remoteDatasetStore);
    const setType = (type: FinancialSourceType) => {
        setTypeRaw(type);
        setFilterValue(remoteDataset.filterStore, "type", type);
    };
    const setYear = (year: number) => {
        setYearRaw(year);
        setFilterValue(remoteDataset.filterStore, "year", year);
    };

    const [selected, setSelected] = useState<FinancialLimit | undefined>();
    const selectedFormatted = selected
        ? `Код: ${selected.kvr}-${selected.kosgu}`
        : "";
    const isOk = !!amount && amount.gt(0) && !!selected;

    const save = () => {
        if (!(!!amount && amount.gt(0) && !!selected)) return;

        x.done({
            source: selected,
            amount: amount,
        });
    };

    const footer = <HStack>
        <Button title="Выбрать" color="green" onClick={save} disabled={!isOk} />
        <Expandee/>
        <Button color="red" icon="aClose" onClick={() => x.done(undefined)} />
    </HStack>;

    const typeRadioProps = {
        value: type,
        onChange: setType,
        labelPreset: "readonlyValue" as LabelPresets
    };

    return <CardModal title="Выбор финансирования" close={() => x.done(undefined)} width="50%" bottom={footer}>
        <VStack spacing="15px">
            <VGrid columns="1fr 1fr" spacing="15px">
                <FieldStack>
                    <Field title="Тип финансирования" required>
                        <RadioGroup>
                            <VStack spacing="3px">
                                <Radio requiredValue={FinancialSourceType.LIMIT} {...typeRadioProps} disabled>
                                    Бюджет города Москвы
                                </Radio>
                                <Radio requiredValue={FinancialSourceType.LIMIT} {...typeRadioProps} disabled>
                                    Федеральные средства
                                </Radio>
                                <Radio requiredValue={FinancialSourceType.FIN_ACTIONS} {...typeRadioProps}>
                                    ПФХД
                                </Radio>
                                <Radio requiredValue={FinancialSourceType.OWN_FUNDS} {...typeRadioProps} disabled>
                                    Собственные средства
                                </Radio>
                            </VStack>
                        </RadioGroup>
                    </Field>
                    <Field title="Сумма закупки" required>
                        <DecimalBox value={amount} precision={2} onChange={setAmount} alignment="right"/>
                    </Field>
                </FieldStack>
                <FieldStack>
                    <Field title="Год" required>
                        <ComboBox options={yearOptions} value={year} onChange={setYear} />
                    </Field>
                    <Field title="Текущий источник" required>
                        <Label preset="readonlyValue" text={selectedFormatted}/>
                    </Field>
                </FieldStack>
            </VGrid>
            <SelectionTable<FinancialLimit>
                dataset={remoteDataset.dataset} mode="single" selected={selected}
                selectorPosition="hidden"
                onChange={setSelected} columns={[
                    Table.Column("КВР", x => <>{x.item.kvr}</>),
                    Table.Column("КОСГУ", x => <>{x.item.kosgu}</>),
                    Table.Column("КВФО", x => <>{x.item.kvfo}</>),
                    Table.Column("Сумма лимита", x => <>{formatNumber(x.item.price)}</>),
                    Table.Column("Запланировано", x => <>{formatNumber(x.item.plannedAmount)}</>),
                    Table.Column("Остаток", x => <>{formatNumber(x.item.price.sub(x.item.plannedAmount))}</>)
            ]} />
            <Pager remoteDataset={remoteDataset}/>
        </VStack>
    </CardModal>;
};
