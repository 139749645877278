import React, {useEffect, useState} from 'react';
import {ModalFC} from "@/EventBus";
import Decimal from "decimal.js";
import {Button, DatePicker, DecimalBox, IntBox, Label} from "@/components/primitive";
import {CardModal} from "@/components/modals/CardModal";
import {Field, Fields} from "@/components/form/Field";
import {HStack} from "@/components/layouts";
import {Table} from "@/components/table";
import {FinancialSourceTypeStrings} from "@/models/ComposedLots/FinancialLimit";
import {LotPlannedPayment} from "@/models/ComposedLots/LotPlannedPayment";
import {formatNumber} from "@/NumberFormatting";
import {LotFinancialSourceUsage} from "@/models/ComposedLots/LotFinancialSourceUsage";
import { PaymentStage } from '@/models/ComposedLots/PaymentStage';
import {LotInfo} from "@/views/ComposedLot/shared/store/lotInfo";
import {LawType} from "@/models/enums";
import { observer } from "mobx-react";
import {toJS} from "mobx";
import {compareSupplyStageByDate} from "@/models/ComposedLots/specifications";

interface AddPaymentStageProps {
    law: LawType
    totalPrice: Decimal
    selectedStage: PaymentStage
    payment: Decimal
    lotCreationDate: Date
    startPricePercent: Decimal
    plannedPayments: LotPlannedPayment[]
    paymentStages: PaymentStage[]
}

export const AddPaymentStageModal: ModalFC<AddPaymentStageProps, PaymentStage> = observer(x => {
    const noPayments = x.totalPrice.eq(0);
    const is223 = x.law === LawType.F223;

    const [paymentDate, setPaymentDate] = useState<Date | undefined>(x.selectedStage?.paymentDate);
    const [offsetPaymentDate, setOffsetPaymentDate] = useState<number | undefined>(x.selectedStage?.paymentDateOffset);


    const getUsageStageBySource = (sources: LotFinancialSourceUsage[]): LotFinancialSourceUsageModal[] => {
        return x.plannedPayments.map(p => {
            const source = sources.find(source => source.sourceId === p.source.id);
            console.log("stage", toJS(x.selectedStage));
            console.log("stages", x.paymentStages);
            console.log("filter stages", x.paymentStages.filter(p => p !== x.selectedStage));
            return {
                sourceId: p.source.id,
                paymentAmount: source?.paymentAmount ?? new Decimal(0),
                advanceAmount: source?.advanceAmount ?? new Decimal(0),
                plannedPayment: p,
                availableAmountPayment: p.amount.sub(x.paymentStages.filter(p => !compareSupplyStageByDate(p.supplyStage, x.selectedStage.supplyStage, x.lotCreationDate)).flatMap(f => f.financialSourceUsage).filter(f => f.sourceId === p.source.id).map(f => f.paymentAmount ?? new Decimal(0)).reduce((v, s) => s.add(v), new Decimal((0))))
            } as LotFinancialSourceUsageModal;
        });
    };

    const [financialSourceUsage, setFinancialSourceUsage] = useState<LotFinancialSourceUsageModal[]>(getUsageStageBySource(x.selectedStage?.financialSourceUsage ?? []));

    useEffect(() => {
        getUsageStageBySource(financialSourceUsage);
    }, [financialSourceUsage]);

    const [startPricePercent, _setStartPricePercent] = useState<Decimal>(x.startPricePercent);
    const [amount, _setAmount] = useState<Decimal>(x.payment);
    const [advancePercent, _setAdvancePercent] = useState<Decimal>(() => financialSourceUsage.map(s => s.advanceAmount ?? new Decimal(0)).reduce((p, s) => s.add(p).toDecimalPlaces(2), new Decimal(0)).div(financialSourceUsage.map(s => s.paymentAmount ?? new Decimal(0)).reduce((p, s) => s.add(p), new Decimal(0))).mul(100));
    const [advanceAmount, _setAdvanceAmount] = useState<Decimal>(() => financialSourceUsage.map(s => s.advanceAmount ?? new Decimal(0)).reduce((p, s) => s.add(p).toDecimalPlaces(2), new Decimal(0)));

    const canSave = !noPayments || is223;

    const setStartPricePercent = (v?: Decimal) => {
        let val = v ?? new Decimal(0);
        if (val?.comparedTo(new Decimal(100)) === 1) val = new Decimal(100);

        _setStartPricePercent(val); // Тут и далее - DecimalBox падает от отсутствия значения
        const _am = x.totalPrice.div(100).mul(val); // Изменившийся размер платежа
        _setAmount(_am);
        if (advanceAmount) _setAdvanceAmount(advanceAmount.div(val).mul(100)); // Считаем изменившийся процент аванса

    };

    const setAmount = (v?: Decimal) => {
        const val = v ?? new Decimal(0);
        _setAmount(val);
        _setStartPricePercent(val.div(x.totalPrice).mul(100)); // Считаем процент от НМЦ относительно введенных данных
        _setAdvanceAmount(advanceAmount.div(val).mul(100)); // Считаем изменившийся процент аванса
    };

    const setAdvancePercent = (v?: Decimal) => {
        let val = v ?? new Decimal(0);
        if (val?.comparedTo(new Decimal(100)) === 1) val = new Decimal(100);

        _setAdvancePercent(val);
        _setAdvanceAmount(amount.div(100).mul(val));
    };

    const setAdvanceAmount = (v?: Decimal) => {
        const val = v ?? new Decimal(0);

        _setAdvanceAmount(val);
        _setAdvancePercent(val.div(amount).mul(100));
    };

    interface LotFinancialSourceUsageModal extends LotFinancialSourceUsage {
        plannedPayment: LotPlannedPayment
        availableAmountPayment: Decimal
    }

    const save = () => {
        if (is223) {
            if (!financialSourceUsage.length) {
                console.error("unable to save payment stage - missing financial source usage");
                return;
            }
            const payment: LotFinancialSourceUsage = { ...financialSourceUsage[0], paymentAmount: amount, advanceAmount: advanceAmount  };

            x.done({
                paymentDate: paymentDate!,
                paymentDateOffset: offsetPaymentDate!,
                specifications: x.selectedStage?.specifications,
                supplyStage: x.selectedStage?.supplyStage!,
                financialSourceUsage: [payment]
            });
        } else {
            x.done({
                paymentDate: paymentDate!,
                paymentDateOffset: offsetPaymentDate!,
                specifications: x.selectedStage?.specifications,
                supplyStage: x.selectedStage?.supplyStage!,
                financialSourceUsage: financialSourceUsage
            });
        }
    };

    const bottom = <HStack reverse spacing="10px">
        <Button icon="aClose" color="red" onClick={() => x.done(undefined)}/>
        <Button icon="aSave" disabled={!canSave} onClick={save} />
    </HStack>;

    return <CardModal title="Новая дата оплаты" width="60%" close={() => x.done(undefined)} bottom={bottom}>
        <Fields orientation="horizontal" style={{marginBottom: "30px"}}>
            <Field title="Дата начала поставки" >
                <HStack spacing="20px" alignItems="center">
                    {
                        x.selectedStage?.supplyStage.type === "ABSOLUTE"
                            ? <DatePicker value={x.selectedStage?.supplyStage.startDate ?? undefined} disabled={true} mode="days"/>
                            :
                                <>
                                <IntBox value={x.selectedStage?.supplyStage.startOffset} disabled={true} style={{ width: "50%" }}/>
                                <Label text="дней с даты заключения контракта"/>
                                </>
                    }
                </HStack>
            </Field>
            <Field title="Дата окончания поставки">
                <HStack spacing="20px" alignItems="center">
                {
                        x.selectedStage?.supplyStage.type === "ABSOLUTE"
                            ? <DatePicker value={x.selectedStage?.supplyStage.endDate} disabled={true} mode="days"/>
                            : <>
                                <IntBox value={x.selectedStage?.supplyStage.endOffset} disabled={true} style={{ width: "50%" }}/>
                                <Label text="дней с даты заключения контракта"/>
                            </>
                    }
                </HStack>
            </Field>
            <Field title="Дата платежа">
                <HStack spacing="20px" alignItems="center">
                {
                    x.selectedStage?.supplyStage.type === "ABSOLUTE"
                        ? <DatePicker value={paymentDate} onChange={setPaymentDate} mode="days" alignRight={true}/>
                        : <>
                            <IntBox value={offsetPaymentDate} onChange={setOffsetPaymentDate} style={{ width: "50%" }}/>
                            <Label text="дней с даты заключения контракта"/>
                        </>
                }
                </HStack>
            </Field>
            <Field title="Процент от НМЦ контракта">
                <HStack spacing="20px" alignItems="center">
                    <DecimalBox disabled={!is223}
                                precision={2}
                                value={startPricePercent ?? new Decimal(0)}
                                onChange={v => setStartPricePercent(v)}
                                style={{ width: "50%" }}/>
                    <Label>% </Label>
                </HStack>
            </Field>
            <Field title="Сумма платежа">
                <HStack spacing="20px" alignItems="center">
                    <DecimalBox disabled={!is223}
                                value={amount}
                                precision={2}
                                onChange={v => setAmount(v)}
                                style={{ width: "50%" }}/>
                    <Label>Российский рубль </Label>
                </HStack>
            </Field>
            <Field title="Размер аванса">
                <HStack spacing="20px" alignItems="center">
                    <DecimalBox disabled={!is223}
                                precision={2}
                                value={advancePercent}
                                onChange={v => setAdvancePercent(v)}
                                style={{ width: "50%" }}/>
                    <Label>%</Label>
                </HStack>
            </Field>
            <Field title="Сумма аванса">
                <HStack spacing="20px" alignItems="center">
                    <DecimalBox disabled={!is223}
                                value={advanceAmount}
                                precision={2}
                                onChange={v => setAdvanceAmount(v)}
                                style={{ width: "50%" }}/>
                    <Label>Российский рубль </Label>
                </HStack>
            </Field>
        </Fields>
        {x.plannedPayments.length > 0 && !is223 &&
            <Table<LotFinancialSourceUsageModal> dataset={financialSourceUsage} columns={
                [
                Table.AutoColumn("№", c => <>{c.rowIndex + 1}</>),
                Table.Column("Источник финансирования", c =>
                    <Label preset="readonlyValue">
                        {FinancialSourceTypeStrings[c.item.plannedPayment.source.type]}
                    </Label>),
                Table.Column("Сумма источника", c =>
                    <Label preset="readonlyValue">
                        {formatNumber(c.item.plannedPayment.amount)}
                    </Label>),
                Table.Column("Не распределено в этапы, руб.", c =>
                    <Label preset="readonlyValue">
                        {formatNumber(c.item.availableAmountPayment.sub(c.item.paymentAmount ?? new Decimal(0)))}
                    </Label>),
                Table.Column("Сумма в этапе", c =>
                        <DecimalBox value={c.item.paymentAmount}
                                    onChange={v =>
                                    { setFinancialSourceUsage(financialSourceUsage.map(el => el.sourceId === c.item.sourceId? { ...el, paymentAmount: v }: el)) }}/>),
                Table.Column("В том числе авансовый платеж, руб.", c =>
                        <DecimalBox value={c.item.advanceAmount}
                                    onChange={v =>
                                    { setFinancialSourceUsage(financialSourceUsage.map(el => el.sourceId === c.item.sourceId? { ...el, advanceAmount: v }: el)) }}/>,
                    {width: "2fr"})]}/>
        }
    </CardModal>;
});
