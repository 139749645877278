import React, {useContext, useEffect} from "react";
import {
    getRequirement,
    ProvisionKind,
    setAcceptor,
    setAccount,
    setChargedWarranty,
    setDefaultOrderValue,
    setExecutionCostPercent,
    setManufacturersWarrantyReq,
    setMinimalTerm,
    setOrder,
    setPercent,
    setServiceCostPercent,
    setSpecialOrder,
    setUsed,
    setWarrantyServiceReq, setWarrantyTerm
} from "@/views/ComposedLot/shared/store/provisions";
import {FCheckBox, FComboBox, FDecimalBox, FIntBox, FormStateContext, FTextBox} from "@/components/form";
import {useMappedStore} from "@/storeUtils";
import {Card} from "@/components/Card";
import {Field, FieldInset, Fields} from "@/components/form/Field";
import {CheckBox, Label} from "@/components/primitive";
import { Expandee, HStack, VStack } from "@/components/layouts";
import {formatNumber} from "@/NumberFormatting";
import {SectionProps} from "@/views/ComposedLot/shared/store";
import {getTotalPaymentAmount} from "@/views/ComposedLot/shared/store/plannedPayments";

interface ProvisionSectionProps extends SectionProps {
    kind: ProvisionKind
    is223: boolean
    hasRequestError?: boolean
    isLifecycle?: boolean
}

const getOrderTitle = (provision: ProvisionKind): string => {
    switch (provision) {
        case "request":
            return "Порядок внесения обеспечения заявки";
        case "guarantee":
            return "Особенности внесения";
        case "contract":
            return "Порядок предоставления обеспечения исполнения контракта";
    }
};

const getUsageTitle = (provision: ProvisionKind, is223: boolean): string => {
    switch (provision) {
        case "request":
            return "Взимается обеспечение заявки";
        case "guarantee":
            return "Взимается обеспечение исполнения гарантийных обязательств";
        case "contract":
            return `Взимается обеспечение исполнения ${is223 ? "договора" : "контракта"}`;
    }
};

const getUnusedTitle = (provision: ProvisionKind, is223: boolean): string => {
    switch (provision) {
        case "request":
            return "Нет";
        case "guarantee":
            return "Требование об обеспечении исполнения гарантийных обязательств не установлено";
        case "contract":
            return `Обеспечение исполнения ${is223 ? "договора" : "контракта"} не указывается.`;
    }
};

const getSizeTitle = (provision: ProvisionKind, is223: boolean): string => {
    switch (provision) {
        case "request":
            return "Размер обеспечения заявки (%)";
        case "guarantee":
            return "Размер обеспечения исполнения гарантийных обязательств (%)";
        case "contract":
            return `Размер обеспечения исполнения ${is223 ? "договора" : "контракта"} (%)`;
    }
};

const getAccountTitle = (provision: ProvisionKind, is223: boolean): string => {
    switch (provision) {
        case "request":
            return "Банковские реквизиты обеспечения заявки";
        case "guarantee":
            return "Банковские реквизиты исполнения гарантийных обязательств";
        case "contract":
            return `Банковские реквизиты исполнения ${is223 ? "договора" : "контракта"}`;
    }
};

export const ProvisionSection: React.FC<ProvisionSectionProps> = x => {
    const state = useContext(FormStateContext);
    const orientation = state === "readonly" ? "vertical" : "horizontal";

    const [req, provision, payments, accounts, providerSelectionType, head, startPrice, law, plannedYear] = useMappedStore(x.formStore, s => [
        getRequirement(x.kind, s.info.providerSelectionType ?? "E_AUC", s.info.plannedNotificationPublishYear),
        s.provisions[x.kind],
        s.plannedPayments.sources,
        s.relatedData.accounts,
        s.info.providerSelectionType,
        s.head,
        getTotalPaymentAmount(s.plannedPayments.sources),
        s.info.law,
        s.info.plannedNotificationPublishYear
    ]);

    const isSingleProviderPurchase = providerSelectionType === "SINGLE_PROVIDER_PURCHASE";

    const total = getTotalPaymentAmount(payments);
    const accountOptions = accounts.map(x => ({key: x.id, desc: x.accountNumber}));
    const setAcc = (id: number) =>
        setAccount([x.kind, accounts.find(x => x.id === id)]);

    useEffect(() => {
        setDefaultOrderValue(["request", x.is223]);
        setDefaultOrderValue(["contract", x.is223]);
    }, []);

    const requestPercentLowPriceValid = Boolean(x.kind === "request" &&
        provision.percent?.gte(0.5) && provision.percent?.lte(1));

    const requestPercentHighPriceValid = Boolean(x.kind === "request"
        && providerSelectionType === "E_QUOTATIONS_REQUEST" && provision.percent?.gte(0.5) && provision.percent?.lte(5));

    const remainingFields = provision.used && (req !== "forbidden" || isSingleProviderPurchase) && <>
        {!x.isLifecycle && <Field title={getSizeTitle(x.kind, x.is223)}>
            <VStack spacing="10px">
                <HStack spacing="15px" alignItems="center">
                    <FDecimalBox style={{width: "20%"}}
                                 value={provision.percent}
                                 onChange={v => setPercent([x.kind, v])}/>
                    <Label preset="readonlyValue">
                        = {formatNumber(total.mul(provision.percent ?? 0).div(100))} Российский рубль
                    </Label>
                </HStack>
                {(startPrice.lte(20e6) && law === "F44" && x.kind === "request" && !requestPercentLowPriceValid) &&
                    <Label preset="readonlyValue" style={{ color: "red" }}>
                        Размер обеспечения заявки должен составлять от 0.5% до 1% начальной (максимальной) цены контракта
                    </Label>}
                {(startPrice.gt(20e6) && law === "F44" && plannedYear > 2021 && x.kind === "request" && !requestPercentHighPriceValid) &&
                  <Label preset="readonlyValue" style={{ color: "red" }}>
                    Размер обеспечения заявки должен составлять от 0.5% до 5% начальной (максимальной) цены контракта
                  </Label>}
            </VStack>
        </Field>}
        {x.kind === "contract" && !!x.isLifecycle && <>
            <Field title="Размер обеспечения исполнения обязательств по поставке товаров или выполнению работы (%)">
                <HStack spacing="15px" alignItems="center">
                    <FDecimalBox style={{width: "20%"}}
                                 value={provision.executionCostPercent}
                                 onChange={v => setExecutionCostPercent(["contract", v])}/>
                    <Label preset="readonlyValue">
                        = {formatNumber(total.mul(provision.executionCostPercent ?? 0).div(100))} Российский рубль
                    </Label>
                </HStack>
            </Field>
            <Field title="Размер обеспечения исполнения обязательств по последующему обслуживанию, эксплуатации, ремонту и утилизации (%)">
                <HStack spacing="15px" alignItems="center">
                    <FDecimalBox style={{width: "20%"}}
                                 value={provision.serviceCostPercent}
                                 onChange={v => setServiceCostPercent(["contract", v])}/>
                    <Label preset="readonlyValue">
                        = {formatNumber(total.mul(provision.serviceCostPercent ?? 0).div(100))} Российский рубль
                    </Label>
                </HStack>
            </Field>
        </>}
        { x.is223 && x.kind == "guarantee" &&
            <Field title={"Минимальный срок гарантийных обязательств (календ. дней)"} required>
                <HStack spacing="15px" alignItems="center">
                    <FIntBox style={{width: "20%"}}
                        value={provision.minimalTerm}
                        onChange={v => setMinimalTerm([x.kind, v])}/>
                </HStack>
            </Field>
        }
        <Field title={getAccountTitle(x.kind, x.is223)}>
          <Expandee/>
        </Field>
        <Field title="УНК заказчика">
            <Label preset="readonlyValue" text="451853"/>
        </Field>
        <Field title="Краткое наименование заказчика">
            <Label preset="readonlyValue" text={head.customer?.shortName ?? ""}/>
        </Field>
        <Field title="Полное наименование заказчика">
            <Label preset="readonlyValue" text={head.customer?.fullName ?? ""}/>
        </Field>
        <Field title="ИНН заказчика">
            <Label preset="readonlyValue" text={head.customer?.tin ?? ""}/>
        </Field>
        <Field title="КПП заказчика">
            <Label preset="readonlyValue" text={head.customer?.rrc ?? ""}/>
        </Field>
        <Field title="БИК">
            <Label preset="readonlyValue" text={provision.account?.bin ?? ""}/>
        </Field>
        <Field title="Наименование банка">
            <Label preset="readonlyValue" text={provision.account?.bankName ?? ""}/>
        </Field>
        <Field title="Город">
            <Label preset="readonlyValue" text={provision.account?.city ?? ""}/>
        </Field>
        <Field title="Корреспондентский счёт">
            <Label preset="readonlyValue" text={provision.account?.correspondentAccount ?? ""}/>
        </Field>
        <Field title="Лицевой счёт">
            <FComboBox options={accountOptions} value={provision.account?.id}
                       onChange={setAcc}/>
        </Field>
        <Field title="Расчетный счёт">
            <Label preset="readonlyValue" text={provision.account?.transactionalAccountNumber ?? ""}/>
        </Field>
            {
                (providerSelectionType === "E_EXAM"
                    || providerSelectionType === "E_AUC"
                    || providerSelectionType === "E_QUOTATIONS_REQUEST") && <>
                    {x.kind !== "guarantee" &&
                    <Field title="Стандартный порядок внесения">
                      <FCheckBox value={!provision.specialOrder} onChange={v => {
                          setSpecialOrder([x.kind, !v]);
                          v && setDefaultOrderValue([x.kind, x.is223]);
                      }}/>
                    </Field>}
                    <Field title={getOrderTitle(x.kind)}>
                      <FTextBox lines={5} disabled={!provision.specialOrder}
                                value={provision.order} onChange={v => setOrder([x.kind, v])}/>
                    </Field>
                </>
            }
          <Field title="Получатель">
            <FTextBox value={provision.acceptor} onChange={v => setAcceptor([x.kind, v])}/>
          </Field>
    </>;

    return <Card>
        <Fields orientation={orientation}>
            {
                x.kind === "guarantee" && !x.is223
                    ? <>
                        <Field title="Устанавливаются требования к гарантии качества товаров, работ, услуг">
                            {
                                (req !== "forbidden" || isSingleProviderPurchase) &&
                                <FCheckBox value={provision.chargedWarranty} onChange={v => setChargedWarranty([x.kind, v])} valueRepr={provision.chargedWarranty ? "Да" : "Нет"} />
                            }
                            {
                                (!provision.chargedWarranty || req === "forbidden") && <FieldInset>
                                    <Label preset="readonlyValue" text={getUnusedTitle(x.kind, x.is223)}/>
                                </FieldInset>
                            }
                        </Field>
                        {
                            provision.chargedWarranty && <>
                                <Field title={"Информация о требованиях к гарантийному обслуживанию товаров"}>
                                    <FTextBox lines={5} value={provision.warrantyServiceReq} onChange={v => setWarrantyServiceReq([x.kind, v])} valueRepr="-"/>
                                </Field>
                                <Field title={"Требования к гарантии производителя товара"}>
                                    <FTextBox lines={5} value={provision.manufacturersWarrantyReq} onChange={v => setManufacturersWarrantyReq([x.kind, v])} valueRepr="-"/>
                                </Field>
                                <Field required title={"Срок, на который предоставляется гарантия и (или) требования к объему предоставления гарантий качества товара, работы, услуги "}>
                                    <FTextBox lines={5} value={provision.warrantyTerm} onChange={v => setWarrantyTerm([x.kind, v])} valueRepr="-"/>
                                </Field>
                            </>
                        }
                        { provision.chargedWarranty && <>
                            <Field title={getUsageTitle(x.kind, x.is223)}>
                                <>{
                                    (req !== "forbidden" || isSingleProviderPurchase) &&
                                    <FCheckBox value={provision.used} onChange={v => setUsed([x.kind, v])} valueRepr=""/>
                                }</>
                            </Field>
                            {
                                (!provision.used || req === "forbidden") && <FieldInset>
                                    <Label preset="readonlyValue" text={getUnusedTitle(x.kind, x.is223)}/>
                                </FieldInset>
                            }
                            { remainingFields }
                        </> }
                    </>
                    : <>
                        <Field title={getUsageTitle(x.kind, x.is223)}>
                            <>{
                                (req !== "forbidden" || isSingleProviderPurchase) &&
                                <FCheckBox value={provision.used} onChange={v => setUsed([x.kind, v])} valueRepr=""/>
                            }</>
                        </Field>
                        {
                            (!provision.used || req === "forbidden") && <FieldInset>
                                <Label preset="readonlyValue" text={getUnusedTitle(x.kind, x.is223)}/>
                            </FieldInset>
                        }
                        { remainingFields }
                    </>
            }

        </Fields>
    </Card>;
};
