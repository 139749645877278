import React, { useState } from 'react';
import { ModalFC, showModal } from "@/EventBus";
import { Button } from "@/components/primitive";
import { CardModal } from "@/components/modals/CardModal";
import { HStack, VGrid } from "@/components/layouts";
import { SpecificationDraft } from "@/models/ComposedLots/specifications";
import { Selection } from "@/modal-views/PickSpecificationModal/Selection";
import { Chars } from "@/modal-views/PickSpecificationModal/Chars";
import { Price } from "@/modal-views/PickSpecificationModal/Price";
import { SupplyStages } from "@/modal-views/PickSpecificationModal/SupplyStages";
import { createSpecificationStore, PickSpecificationProps } from "@/modal-views/PickSpecificationModal/store";
import { useMappedStore } from "@/storeUtils";
import { CharacteristicValue } from "@/models/ComposedLots/specifications/CharacteristicValue";
import { Card } from "@/components/Card";
import { Field, Fields } from "@/components/form/Field";
import { observer } from "mobx-react";
import { LotSpecifications } from "@/views/ComposedLot/shared/store/specifications";

const SaveSpecModal: ModalFC<{desc: string}> = x => {
    const bottom = <HStack reverse>
        <Button onClick={x.done} title="Закрыть" />
    </HStack>;

    return <CardModal title="Сохранение спецификации" close={x.done} width="25%" bottom={bottom} style={{alignSelf: "center"}}>
        {x.desc}
    </CardModal>;
};

export const PickSpecificationModal: ModalFC<PickSpecificationProps, SpecificationDraft> = observer(x => {
    const [store] = useState(() =>
        createSpecificationStore(x));
const existing = LotSpecifications.specificationForms.find(s => s.specId === x.existingSpecificationId)?.validated;
    const [showAllCards, canSave, subjectClass, subjectDeclaration, volume, measurementUnitId, actionClass, additionalCharacteristicJustification] = useMappedStore(store,
            s => [
                !!s.subjectClass && !!s.subjectDeclaration || x.isClone,
                !!s.subjectClass && !!s.subjectDeclaration && !!s.volume && !!s.measurementUnitId,
                s.subjectClass,
                s.subjectDeclaration,
                s.volume,
                s.measurementUnitId,
                s.actionClass,
                s.additionalCharacteristicJustification
            ]);

    const save = () => {
        const state = store.getState();

        if(!state.supplyStages.length) {
            showModal(SaveSpecModal, {desc: "Укажите сведения о поставке"});
            return;
        }

        if(x.law === "F223" && !state.actionClass) {
            showModal(SaveSpecModal, {desc: "Укажите ОКВЭД-2"});
            return;
        }

        if (!state.subjectDeclaration || !state.volume) return;

        const measurementUnit = state.subjectDeclaration.measurementUnits.find(x => x.id === state.measurementUnitId);
        if (!measurementUnit) return;

        const keepStartPrice =
            !!state.existingDraft &&
            state.subjectDeclaration.id === state.existingDraft.subjectDeclaration.id &&
            state.volume.eq(state.existingDraft.volume);

        const keepId = !x.cloneOptions;

        const specPart = {
            subjectDeclaration: state.subjectDeclaration,
            volume: state.volume,
            measurementUnit: measurementUnit,
            supplyStages: state.supplyStages,
            characteristics: state.requiredChars.concat(state.additionalChars).map(x => ({
                declaration: x.declaration,
                ...x.store.getState()
            } as CharacteristicValue)),
            startPrice: keepStartPrice ? state.existingDraft?.startPrice : undefined,
            supplyAddress: null!,
            actionClass: state.actionClass,
            additionalCharacteristicJustification
        };

        const spec: SpecificationDraft = keepId && x.existingSpecificationId ? {
            ...specPart,
            id: existing!.id,
            isNew: false,
        } : {
            ...specPart,
            isNew: true
        };

        x.done(spec);
    };

    const bottom = <HStack reverse spacing="10px">
        <Button icon="aClose" color="red" onClick={() => x.done(undefined)}/>
        {
            !x.readonly &&
                <Button icon="aSave" disabled={x.law === "F44" ? !canSave && !!actionClass : !canSave} onClick={save} />
        }
    </HStack>;

    const title = x.readonly
                    ? "Просмотр спецификации"
                    : x.isClone
                        ? "Клонирование спецификации"
                        : (x.existingSpecificationId ? "Редактирование спецификации" : "Добавление спецификации");

    return <CardModal title={title} width="60%" close={() => x.done(undefined)} bottom={bottom} cardContentStyle={{padding: 0}}>
        {
            x?.isClone
                ? null
                : x.readonly
                    ? <Card title={"Содержание закупки"}>
                        <VGrid columns={"1fr 1fr"}>
                            <Fields orientation={"vertical"}>
                                <Field title={"КПГЗ"} preset="readonlyTitle"><>{subjectClass?.toString() ?? ""}</>
                                </Field>
                                <Field title={"ОКПД-2"}
                                       preset="readonlyTitle"><>{existing?.subjectDeclaration?.okpd2.name ?? ""}</>
                                </Field>
                            </Fields>
                            <Fields orientation={"vertical"}>
                                <Field title={"СПГЗ"} preset="readonlyTitle"><>{subjectDeclaration?.toString() ?? ""}</>
                                </Field>
                                <Field title={"ID СПГЗ"} preset="readonlyTitle"><>{subjectDeclaration?.id ?? ""}</>
                                </Field>
                            </Fields>
                        </VGrid>
                    </Card>
                    : <Selection store={store} baseSubjectClass={x.baseSubjectClass} law={x.law}/>
        }
        {
            showAllCards && <>
            {
                !x.readonly &&
                    <>
                        {x.isClone ? null : x.law !== "F223" && <Chars store={store} kind="rules"/>}
                        <Chars store={store} kind="required"/>
                        <Chars store={store} kind="additional"/>
                    </>
            }
                <Price store={store} perItem={x.perItem} readonly={x.readonly} />
                <SupplyStages store={store} readonly={x.readonly} perItem={x.perItem}/>
            </>
        }
    </CardModal>;
});
