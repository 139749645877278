import {AutoDto, AutoModel, MDMap} from "@/models/parsing";
import {BankAccount} from "@/models/BankAccount";
import {IDtoBankAccount} from "@/models/json/IDtoBankAccount";
import {Decimal} from 'decimal.js';

interface LotProvisionMD {
    used: boolean
    percent: Decimal
    minimalTerm?: number
    account: MDMap<BankAccount, IDtoBankAccount>
    acceptor?: string
    additionalInfo?: string
    executionCostPercent?: Decimal
    serviceCostPercent?: Decimal
    // https://rt.rtall.ru/redmine/issues/803
    chargedWarranty?: boolean // Устанавливаются требования к гарантии качества товаров, работ, услуг
    warrantyServiceReq?: string // Информация о требованиях к гарантийному обслуживанию товаров
    manufacturersWarrantyReq?: string // Требования к гарантии производителя товара
    warrantyTerm?: string // Срок, на который предоставляется гарантия и (или) требования к объему предоставления гарантий качества товара, работы, услуги *
}

export type DtoLotProvision = AutoDto<LotProvisionMD>;
export type LotProvision = AutoModel<LotProvisionMD>;

export const LotProvision = {
    toDto(info: LotProvision): DtoLotProvision {
        return {
            ...info,
            account: info.account.toJson(),
            percent: info.percent.toString(),
            executionCostPercent: info.executionCostPercent?.toString(),
            serviceCostPercent: info.executionCostPercent?.toString()
        };
    },
    fromDto(info: DtoLotProvision): LotProvision {
        return {
            ...info,
            acceptor: info.acceptor ?? undefined,
            additionalInfo: info.additionalInfo ?? undefined,
            account: BankAccount.fromJson(info.account),
            minimalTerm: info.minimalTerm ?? undefined,
            percent: new Decimal(info.percent),
            chargedWarranty: info.chargedWarranty ?? undefined,
            warrantyServiceReq: info.warrantyServiceReq ?? undefined,
            manufacturersWarrantyReq: info.manufacturersWarrantyReq ?? undefined,
            warrantyTerm: info.warrantyTerm ?? undefined,
            executionCostPercent: new Decimal(info.executionCostPercent ?? 0),
            serviceCostPercent: new Decimal(info.serviceCostPercent ?? 0)
        };
    }
};

interface LotProvisionTemplateMD {
    used: boolean
    percent?: Decimal
    minimalTerm?: number
    accountId?: number
    acceptor?: string
    additionalInfo?: string
    executionCostPercent?:  Decimal
    serviceCostPercent?:  Decimal
    // https://rt.rtall.ru/redmine/issues/803
    chargedWarranty?: boolean // Устанавливаются требования к гарантии качества товаров, работ, услуг
    warrantyServiceReq?: string // Информация о требованиях к гарантийному обслуживанию товаров
    manufacturersWarrantyReq?: string // Требования к гарантии производителя товара
    warrantyTerm?: string // Срок, на который предоставляется гарантия и (или) требования к объему предоставления гарантий качества товара, работы, услуги *
}

export type DtoLotProvisionTemplate = AutoDto<LotProvisionTemplateMD>;
export type LotProvisionTemplate = AutoModel<LotProvisionTemplateMD>;

export const LotProvisionTemplate = {
    toDto(info: LotProvisionTemplate): DtoLotProvisionTemplate {
        return {
            ...info,
            percent: info.percent?.toString(),
            executionCostPercent: info.executionCostPercent?.toString(),
            serviceCostPercent: info.serviceCostPercent?.toString()
        };
    },
    fromDto(info: DtoLotProvisionTemplate): LotProvisionTemplate {
        return {
            ...info,
            accountId: info.accountId ?? undefined,
            acceptor: info.acceptor ?? undefined,
            additionalInfo: info.additionalInfo ?? undefined,
            minimalTerm: info.minimalTerm ?? undefined,
            percent: info.percent ? new Decimal(info.percent) : undefined,
            executionCostPercent: info.executionCostPercent ? new Decimal(info.executionCostPercent) : undefined,
            serviceCostPercent: info.serviceCostPercent ? new Decimal(info.serviceCostPercent) : undefined,
            chargedWarranty: info.chargedWarranty ?? undefined,
            warrantyServiceReq: info.warrantyServiceReq ?? undefined,
            manufacturersWarrantyReq: info.manufacturersWarrantyReq ?? undefined,
            warrantyTerm: info.warrantyTerm ?? undefined,
        };
    }
};
