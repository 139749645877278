import React, {CSSProperties, ReactElement} from "react";
import {SelectOption} from "@/models/enums";
import {Dropdown, IDropdownOption, IDropdownStyles, ResponsiveMode} from "office-ui-fabric-react";
import styles from "./MultiSelectBox.module.css";

export type MultiSelectBoxProps<T> = {
    options: SelectOption<T>[]
    values: T[]
    disabled?: boolean
    style?: CSSProperties
    onChange?: (values: T[]) => void
};

const jsStyles: Partial<IDropdownStyles> = {
    dropdown: {
        border: "none",
        overflow: "hidden",
        selectors: {
            ['&:hover .ms-Dropdown-title']: [{
                borderColor: "#b2b2b2 #e2e2e2 #e2e2e2",
            }],
            ['&:focus:after']: [{
                border: "none !important"
            }]
        }
    },
    root: {
        overflowX: "hidden",
        selectors: {
            ["& .is-disabled .ms-Dropdown-title"]: {
                backgroundColor: "#eee !important"
            }
        }
    },
    title: {
        display: "block",
        paddingTop: "0.3em",
        paddingLeft: "10px",
        backgroundColor: "#fafafa",
        border: "1px solid #b2b2b2 #e2e2e2 #e2e2e2",
        borderColor: "#b2b2b2 #e2e2e2 #e2e2e2",
        fontSize: "14px",
        lineHeight: "19px",
        minHeight: "30px",
        height: "unset",
        boxSizing: "border-box",
        transition: "border-color 0.15s ease-in-out 0.3s",
        boxShadow: "inset 0 1px 1px #e1e1e1 !important",
        borderRadius: 0,
        color: "#555",
        selectors: {
            "&:hover": {
                borderColor: "#b2b2b2 #e2e2e2 #e2e2e2 !important",
            },
            "&:focus": {
                outline: 0,
                borderColor: "#66afe9 !important",
            },
            "&[disabled]": {
                background: "#eee !important"
            },
        },
        overflow: "hidden",
        whiteSpace: "wrap"
    },
    dropdownItemDisabled: {
        color: "#999 !important",
        height: "unset !important"
    },
    dropdownItem: {
        color: "#555 !important",
        height: "unset !important",
        selectors: {
            "&:hover": {
                backgroundColor: "#bad0ff !important"
            },
            ".ms-Checkbox-checkbox": {
                display: "none"
            }
        }
    },
    dropdownOptionText: {
        whiteSpace: "normal !important"
    },
    dropdownItemSelected: {
        backgroundColor: "#7fa8fd !important",
        height: "unset !important",
        color: "#fff !important",
    }
};

export const MultiSelectBox: <T>(x: MultiSelectBoxProps<T>) => ReactElement = x => {
    type T = typeof x.options[0]["key"];

    const mappedOptions = x.options.map((o, i) => ({
        key: i,
        text: o.desc,
        data: o.key,
        hidden: o.key === undefined || x.values.includes(o.key),
        disabled: o.disabled
    } as IDropdownOption));

    const selectedIndices = x.values.map(value => x.options.findIndex(o => o.key === value));

    const toggle = (_: unknown, o?: IDropdownOption) => {
        if (o === undefined) return;

        const d = o.data as T | undefined;
        if (d === undefined) return;

        if (x.values.includes(d)) {
            x.onChange?.(x.values.filter(v => v !== d));
        } else {
            x.onChange?.([...x.values, d]);
        }
    };

    const remove = (key: T) => x.onChange?.(x.values.filter(v => v !== key));

    return <Dropdown options={mappedOptions}
                     styles={{...jsStyles, root: {...jsStyles.root as {}, ...x.style }} as typeof styles}
                     responsiveMode={ResponsiveMode.xxxLarge}
                     selectedKeys={selectedIndices}
                     multiSelect
                     aria-expanded={false}
                     onChange={toggle}
                     onRenderTitle={it => <div className={styles.titleWrap}>
                         {it?.map((i, n) => <div key={n} className={styles.titleItem} onClick={e => e.stopPropagation()}>
                             <span className={styles.titleItemDelButton}
                                   onClick={() => remove(i.data)}>&#xe803;</span>
                             <span className={styles.titleItemText}>{i.text}</span>
                         </div>)}
                     </div>}
                     disabled={x.disabled}
                     calloutProps={{ calloutMaxHeight: 500 }} />;
};
