import { Component, Prop, Vue } from "vue-property-decorator";
import SingleTableView from "@/components/SingleTableView.vue";
import HeadTitle from "@/components/HeadTitle.vue";
import { ITableColumn } from "@/components/TableAbstractions";

import { formatNumber } from "@/NumberFormatting";

import { FinancialActionSource, FinancialSource } from "@/models";
import FinancialSources from "@/api/FinancialSources";
import { getActivePage, getAvailablePages, handlePaginationRequest, PaginationContext, createPaginationContext } from "@/api/Pagination";
import { LawTypeStrings, FinancialSourceType, LawType } from '@/models/enums';
import { FilterValueType, FilterConditionType } from '@/api/http';
import router from '@/router';

type PaginationRequest = "TO_START" | "TO_PREVIOUS" | "TO_NEXT" | "TO_END" | number;

@Component({ components: { SingleTableView, HeadTitle } })
export default class Pvhd extends Vue {
    public finActionsHeaders: ITableColumn<FinancialActionSource>[] = [
        {
            title: "Наименование показателя",
            getter(row) {
                return row.comment;
            },
            size: "4fr"
        },
        {
            title: "КВР",
            getter(row) {
                return row.expenseKind;
            },
        },
        {
            title: "КОСГУ",
            getter(row) {
                return row.govOperationClass;
            },
        },
        {
            title: "КВФО",
            getter(row) {
                return row.provisionCode;
            },
        },
        {
            title: "Закон-основание",
            getter(row) {
                return LawTypeStrings[row.law];
            },
        },
        {
            title: "Сумма лимита",
            getter(row) {
                return formatNumber(row.total);
            },
        },
        {
            title: "Запланировано",
            getter(row) {
                return formatNumber(row.used);
            },
            size: "auto"
        },
        {
            title: "Остаток",
            getter(row) {
                return formatNumber(row.total.sub(row.used));
            },
        },
        {
            title: "",
            action: (r) => r.total.sub(r.used).gte(0) ? ({
                id: 'create-object',
                icon: {
                    type: 'CLASS',
                    name: 'add'
                }
            }) : null,
            getter() { return '' },
        }
    ];

    public context: PaginationContext<FinancialSource> | null = null;

    public async mounted() {
        this.context = await createPaginationContext(
            (n, sz, f) => FinancialSources.get(n, sz, {
                ...f,
                year: [{
                    type: FilterValueType.LONGINT,
                    conditionType: FilterConditionType.EQUAL,
                    longint: new Date().getFullYear(),
                }]
            }), 20);
    }

    public itemAction(item: FinancialActionSource, action: string) {
        switch (action) {
            case "create-object":
                router.push({
                    path: `/plan-objects/composed-lots/create/${item.law}`,
                    query: { financialLimitId: `${item.id}` }
                });
                break;
        }
    }
}
