import { applyN, asDate, asDecimal, AutoDto, AutoModel, MDMap, uuid } from "@/models/parsing";
import { LawType, SmpType } from "@/models/enums";
import { SpecialPurchaseType } from "@/models/ComposedLots/SpecialPurchaseType";
import { ProviderSelectionType } from "@/models/ComposedLots/ProviderSelectionType";
import { Article15Accordance } from "@/models/ComposedLots/Article15Accordance";
import { ContractType } from "@/models/ComposedLots/ContractType";
import { Decimal } from "decimal.js";
import { BankingSupport } from "@/models/ComposedLots/BankingSupport";
import { Participant, SubjectClass } from "@/models";
import { SingleProviderReason } from "@/models/SingleProviderReason";
import {
    DtoPaymentStage,
    DtoPaymentStageTemplate,
    PaymentStage,
    PaymentStageTemplate
} from "@/models/ComposedLots/PaymentStage";
import { IDtoParticipant, IDtoSubjectClass } from "@/models/json";
import { IDtoSingleProviderReason } from "@/models/json/IDtoSingleProviderReason";
import { LotStatus } from "@/models/ComposedLots/LotStatus";
import {
    DtoLotProvision,
    DtoLotProvisionTemplate,
    LotProvision,
    LotProvisionTemplate
} from "@/models/ComposedLots/LotProvision";
import {
    DtoLotPlannedPayment,
    DtoLotPlannedPaymentTemplate,
    LotPlannedPayment, LotPlannedPaymentTemplate
} from "@/models/ComposedLots/LotPlannedPayment";
import { PlanStatus } from "@/models/Plans/PlanStatus";
import {
    DtoLotAdditionalPurchaseInfo,
    DtoLotAdditionalRequirements,
    LotAdditionalPurchaseInfo,
    LotAdditionalRequirements
} from "@/models/ComposedLots/LotAdditionalInfo";
import { CancellationReason, DtoCancellationReason } from "@/models/ComposedLots/CancellationReason";
import { LotResponsibilityInfo } from "@/views/ComposedLot/shared/store/responsibilityInfo";
import { ElectronicExecution } from "@/models/ComposedLots/ElectronicExecution";
import { PurchaseCategory } from "@/models/ComposedLots/PurchaseCategory";
import {PaymentTermType, ReviewDocumentsTermType, SendDocumentsTermType} from "@/models/ComposedLots/PaymentStageTerm";
import { ContractLifecycleConditions, DtoContractLifecycleConditions } from "@/models/ComposedLots/ContractLifecycleConditions";

interface LotBasicInfoMD {
    subject: string
    specialPurchaseType: SpecialPurchaseType
    providerSelectionType: ProviderSelectionType
    singleProviderReasonId?: number
    article15Accordance: Article15Accordance
    contractType: ContractType
    smpType: SmpType
    smpPercent: Decimal
    isGoodsUsed: boolean
    paymentType: "STAGED" | "SINGLE"
    singlePaymentDate?: Date
    contractStartPriceReason?: string
    lotJoinType: "NO" | "MASTER" | "SLAVE"
    plannedPublishDate?: Date
    isPlannedPublishDatePrecise: boolean
    plannedNotificationPublishYear: number
    contractConclusionYear: number
    isAuditServicesPurchase: boolean
    contractTerm?: Date
    advancePercent: Decimal
    isRepairRelated: boolean
    bankingSupport: BankingSupport
    creationDate: Date
    isMonopoly: boolean
}

export type DtoLotBasicInfo = AutoDto<LotBasicInfoMD>;
export type LotBasicInfo = AutoModel<LotBasicInfoMD>;

export const LotBasicInfo = {
    toDto(info: LotBasicInfo): DtoLotBasicInfo {
        return {
            ...info,
            smpPercent: info.smpPercent.toString(),
            advancePercent: info.advancePercent.toString(),
            contractTerm: info.contractTerm?.toISOString(),

            singlePaymentDate: info.singlePaymentDate?.toISOString(),
            plannedPublishDate: info.plannedPublishDate?.toISOString(),
            creationDate: info.creationDate?.toISOString()
        };
    },
    fromDto(info: DtoLotBasicInfo): LotBasicInfo {
        return {
            ...info,
            smpPercent: asDecimal(info.smpPercent),
            advancePercent: asDecimal(info.advancePercent),
            contractTerm: asDate(info.contractTerm),
            singlePaymentDate: asDate(info.singlePaymentDate),
            plannedPublishDate: asDate(info.plannedPublishDate),
            creationDate: asDate(info.creationDate),
            contractStartPriceReason: info.contractStartPriceReason ?? undefined,
            singleProviderReasonId: info.singleProviderReasonId ?? undefined,
            contractType: info.contractType ?? undefined
        };
    }
};

interface LotTemplateMD {
    basicInfo: MDMap<LotBasicInfo, DtoLotBasicInfo>
    subjectClassId?: number
    singleProviderId?: number
    singleProviderReasonId?: number
    contractStartPriceReason?: string
    contractProvision: MDMap<LotProvisionTemplate, DtoLotProvisionTemplate>
    requestProvision: MDMap<LotProvisionTemplate, DtoLotProvisionTemplate>
    guaranteeProvision: MDMap<LotProvisionTemplate, DtoLotProvisionTemplate>
    plannedPayments: MDMap<LotPlannedPaymentTemplate, DtoLotPlannedPaymentTemplate>[]
    purchasePerUnit: boolean
    contractLifecycle: boolean
    contractLifecycleConditions?: MDMap<ContractLifecycleConditions, DtoContractLifecycleConditions>
    minimalRequirements?: string
    customerId?: number
    performerId?: number
    mainCustomerId?: number
    includedLotIds: uuid[]
    purchaseCategories: uuid[]
    paymentStages: MDMap<PaymentStageTemplate, DtoPaymentStageTemplate>[]
}

export type DtoLotTemplate = AutoDto<LotTemplateMD>;
export type LotTemplate = AutoModel<LotTemplateMD>;

export const LotTemplate = {
    toDto(info: LotTemplate): DtoLotTemplate {
        return {
            ...info,
            basicInfo: LotBasicInfo.toDto(info.basicInfo),
            contractProvision: applyN(LotProvisionTemplate.toDto, info.contractProvision),
            requestProvision: applyN(LotProvisionTemplate.toDto, info.requestProvision),
            guaranteeProvision: applyN(LotProvisionTemplate.toDto, info.guaranteeProvision),
            plannedPayments: info.plannedPayments.map(LotPlannedPaymentTemplate.toDto),
            paymentStages: info.paymentStages.map(PaymentStageTemplate.toDto),
            contractLifecycleConditions: ContractLifecycleConditions.toDto(info.contractLifecycleConditions ?? null)
        };
    },
    fromDto(info: DtoLotTemplate): LotTemplate {
        return {
            ...info,
            basicInfo: LotBasicInfo.fromDto(info.basicInfo),
            contractProvision: applyN(LotProvisionTemplate.fromDto, info.contractProvision),
            requestProvision: applyN(LotProvisionTemplate.fromDto, info.requestProvision),
            guaranteeProvision: applyN(LotProvisionTemplate.fromDto, info.guaranteeProvision),
            plannedPayments: info.plannedPayments.map(LotPlannedPaymentTemplate.fromDto),
            contractStartPriceReason: info.contractStartPriceReason ?? undefined,
            singleProviderId: info.singleProviderId ?? undefined,
            singleProviderReasonId: info.singleProviderReasonId ?? undefined,
            subjectClassId: info.subjectClassId ?? undefined,
            minimalRequirements: info.minimalRequirements ?? undefined,
            customerId: info.customerId ?? undefined,
            performerId: info.performerId ?? undefined,
            mainCustomerId: info.mainCustomerId ?? undefined,
            paymentStages: info.paymentStages.map(PaymentStageTemplate.fromDto),
            contractLifecycleConditions: ContractLifecycleConditions.fromDto(info.contractLifecycleConditions ?? null)
        };
    }
};

interface LotMD {
    id: uuid
    law: LawType
    regNumber: number
    procedureId?: uuid
    purchaseCode: string | undefined
    status: LotStatus
    aggregatingLotId?: uuid
    aggregatorStatus?: LotStatus
    basicInfo: MDMap<LotBasicInfo, DtoLotBasicInfo>
    subjectClass?: MDMap<SubjectClass, IDtoSubjectClass>
    singleProvider?: MDMap<Participant, IDtoParticipant>
    singleProviderReason?: MDMap<SingleProviderReason, IDtoSingleProviderReason>
    contractProvision: MDMap<LotProvision, DtoLotProvision>
    requestProvision: MDMap<LotProvision, DtoLotProvision>
    guaranteeProvision: MDMap<LotProvision, DtoLotProvision>
    plannedPayments: MDMap<LotPlannedPayment, DtoLotPlannedPayment>[]
    responsibilityInfo: LotResponsibilityInfo
    purchasePerUnit: boolean
    minimalRequirements?: string
    planId?: uuid
    prevPlanId?: uuid
    planStatus?: PlanStatus
    prevPlanStatus?: PlanStatus
    totalPrice: Decimal
    additionalRequirements: MDMap<LotAdditionalRequirements, DtoLotAdditionalRequirements>
    additionalPurchaseInfo: MDMap<LotAdditionalPurchaseInfo, DtoLotAdditionalPurchaseInfo>
    purchaseCategories: PurchaseCategory[]

    contractId?: uuid
    criteriaAssessmentOrder?: string
    cancellationReason?: MDMap<CancellationReason, DtoCancellationReason>

    performer?: MDMap<Participant, IDtoParticipant>
    customer?: MDMap<Participant, IDtoParticipant>
    mainCustomer?: MDMap<Participant, IDtoParticipant>
    includedLots: MDMap<Lot, DtoLot>[]
    electronicExecution: ElectronicExecution
    paymentStages: MDMap<PaymentStage, DtoPaymentStage>[]
    sendDocumentsTerm: SendDocumentsTermType
    reviewDocumentsTerm: ReviewDocumentsTermType
    paymentTerm: PaymentTermType

    contractLifecycle: boolean
    contractLifecycleConditions?: MDMap<ContractLifecycleConditions, DtoContractLifecycleConditions>
}

export type DtoLot = AutoDto<LotMD>;
export type Lot = AutoModel<LotMD>;

export const Lot = {
    toDto(info: Lot): DtoLot {
        return {
            ...info,
            procedureId: info.procedureId ?? null,
            basicInfo: LotBasicInfo.toDto(info.basicInfo),
            singleProviderReason: info.singleProviderReason?.toJson(),
            subjectClass: info.subjectClass?.toJson(),
            contractProvision: applyN(LotProvision.toDto, info.contractProvision),
            requestProvision: applyN(LotProvision.toDto, info.requestProvision),
            guaranteeProvision: applyN(LotProvision.toDto, info.guaranteeProvision),
            plannedPayments: info.plannedPayments.map(LotPlannedPayment.toDto),
            singleProvider: info.singleProvider?.toJson(),
            totalPrice: info.totalPrice.toString(),
            additionalRequirements: LotAdditionalRequirements.toDto(info.additionalRequirements),
            additionalPurchaseInfo: LotAdditionalPurchaseInfo.toDto(info.additionalPurchaseInfo),
            includedLots: info.includedLots.map(Lot.toDto),
            paymentStages: info.paymentStages.map(PaymentStage.toDto),
            contractLifecycleConditions: ContractLifecycleConditions.toDto(info.contractLifecycleConditions ?? null)
        };
    },
    fromDto(info: DtoLot): Lot {
        return {
            ...info,
            procedureId: info.procedureId ?? undefined,
            basicInfo: LotBasicInfo.fromDto(info.basicInfo),
            singleProviderReason: applyN(SingleProviderReason.fromJson, info.singleProviderReason),
            subjectClass: applyN(SubjectClass.fromJson, info.subjectClass),
            contractProvision: applyN(LotProvision.fromDto, info.contractProvision),
            requestProvision: applyN(LotProvision.fromDto, info.requestProvision),
            guaranteeProvision: applyN(LotProvision.fromDto, info.guaranteeProvision),
            plannedPayments: info.plannedPayments.map(LotPlannedPayment.fromDto),
            singleProvider: applyN(Participant.fromJson, info.singleProvider),
            totalPrice: asDecimal(info.totalPrice),
            additionalRequirements: LotAdditionalRequirements.fromDto(info.additionalRequirements),
            additionalPurchaseInfo: LotAdditionalPurchaseInfo.fromDto(info.additionalPurchaseInfo),
            minimalRequirements: info.minimalRequirements ?? undefined,
            purchaseCode: info.purchaseCode ?? undefined,
            aggregatingLotId: info.aggregatingLotId ?? undefined,
            aggregatorStatus: info.aggregatorStatus ?? undefined,
            planId: info.planId ?? undefined,
            prevPlanId: info.prevPlanId ?? undefined,
            planStatus: info.planStatus ?? undefined,
            prevPlanStatus: info.prevPlanStatus ?? undefined,
            contractId: info.contractId ?? undefined,
            criteriaAssessmentOrder: info.criteriaAssessmentOrder ?? undefined,
            cancellationReason: info.cancellationReason ? CancellationReason.fromDto(info.cancellationReason) : undefined,
            customer: info.customer ? Participant.fromJson(info.customer) : undefined,
            performer: info.performer ? Participant.fromJson(info.performer) : undefined,
            mainCustomer: info.mainCustomer ? Participant.fromJson(info.mainCustomer) : undefined,
            includedLots: info.includedLots.map(Lot.fromDto),
            paymentStages: info.paymentStages.map(PaymentStage.fromDto),
            contractLifecycleConditions: ContractLifecycleConditions.fromDto(info.contractLifecycleConditions ?? null)
        };
    }
};
