import {Decimal} from 'decimal.js';
import {DayType, SupplyStagePeriodType} from "@/models/enums";
import {isEqual} from "date-fns";
import { Address, DtoAddress } from "@/models/ComposedLots/specifications";
import { applyN } from "@/models/parsing";

export interface SupplyStageCommon {
    readonly volume: Decimal
    readonly type: SupplyStagePeriodType
    readonly conditions: string
    readonly address: Address
}

export type SupplyStage = SupplyStageCommon & ({
    readonly type: "RELATIVE"
    readonly startOffset: number
    readonly startOffsetType: DayType
    readonly endOffset: number
    readonly endOffsetType: DayType
    readonly finishYear: number
} | {
    readonly type: "ABSOLUTE"
    readonly startDate: Date | null
    readonly endDate: Date
    readonly fromContractConclusion: boolean
});

interface DtoSupplyStageCommon {
    readonly volume: string
    readonly type: SupplyStagePeriodType
    readonly conditions: string
    address: DtoAddress
}

export type DtoSupplyStage = DtoSupplyStageCommon & ({
    readonly type: "RELATIVE"
    readonly relativePeriod: {
        readonly startOffset: number
        readonly startOffsetType: DayType
        readonly endOffset: number
        readonly endOffsetType: DayType
        readonly finishYear: number
    }
} | {
    readonly type: "ABSOLUTE"
    readonly absolutePeriod: {
        readonly startDate: string | null
        readonly endDate: string
        readonly fromContractConclusion: boolean
    }
});

export const SupplyStage = {
    toDto(info: SupplyStage): DtoSupplyStage {
        return info.type === "ABSOLUTE"
            ? {
                type: "ABSOLUTE",
                volume: info.volume.toString(),
                conditions: info.conditions,
                absolutePeriod: {
                    startDate: info.startDate?.toISOString() ?? null,
                    endDate: info.endDate.toISOString(),
                    fromContractConclusion: info.fromContractConclusion,
                },
                address: applyN(Address.toDto, info.address)
            }
            : {
                type: "RELATIVE",
                volume: info.volume.toString(),
                conditions: info.conditions,
                relativePeriod: {
                    startOffset: info.startOffset,
                    startOffsetType: info.startOffsetType,
                    endOffset: info.endOffset,
                    endOffsetType: info.endOffsetType,
                    finishYear: info.finishYear
                },
                address: applyN(Address.toDto, info.address)
            };
    },
    fromDto(info: DtoSupplyStage): SupplyStage {
        return info.type === "RELATIVE"
            ? {
                type: "RELATIVE",
                volume: new Decimal(info.volume),
                conditions: info.conditions,
                address: applyN(Address.fromDto, info.address),
                ...info.relativePeriod
            }
            : {
                type: "ABSOLUTE",
                volume: new Decimal(info.volume),
                conditions: info.conditions,
                address: applyN(Address.fromDto, info.address),
                fromContractConclusion: info.absolutePeriod.fromContractConclusion,
                startDate: !info.absolutePeriod.fromContractConclusion && info.absolutePeriod.startDate ? new Date(info.absolutePeriod.startDate) : null,
                endDate: new Date(info.absolutePeriod.endDate),
            };
    }
};

export const compareSupplyStageByDate = (firstSupplyStage: SupplyStage, secondSupplyStage: SupplyStage, creationDate: Date): boolean => {
    if (firstSupplyStage.type != secondSupplyStage.type) {
        return false;
    }
    
    if (firstSupplyStage.type == "RELATIVE" && secondSupplyStage.type == "RELATIVE") {
        return firstSupplyStage.endOffset == secondSupplyStage.endOffset && firstSupplyStage.startOffset == secondSupplyStage.startOffset;
    } else if (firstSupplyStage.type == "ABSOLUTE" && secondSupplyStage.type == "ABSOLUTE") {

        const firstStageActualStartDate = firstSupplyStage.startDate ? firstSupplyStage.startDate : firstSupplyStage.fromContractConclusion ? creationDate : null;
        const secondStageActualStartDate = secondSupplyStage.startDate ? secondSupplyStage.startDate : secondSupplyStage.fromContractConclusion ? creationDate : null;

        const isEndEqual = firstSupplyStage.endDate.getDay() === secondSupplyStage.endDate.getDay() 
                             && firstSupplyStage.endDate.getMonth() === secondSupplyStage.endDate.getMonth()
                             && firstSupplyStage.endDate.getFullYear() === secondSupplyStage.endDate.getFullYear();

        if(firstStageActualStartDate === null && secondStageActualStartDate === null) return isEndEqual;
        if(!firstStageActualStartDate || !secondStageActualStartDate) return false;

        //sometimes hh:mm:ss are not equal and check fails. Since hh:mm:ss are not used here, comparing by dd:mm:yyyy only
        const isStartEqual = firstStageActualStartDate.getDay() === secondStageActualStartDate.getDay() 
                             && firstStageActualStartDate.getMonth() === secondStageActualStartDate.getMonth()
                             && firstStageActualStartDate.getFullYear() === secondStageActualStartDate.getFullYear();

        return isStartEqual && isEndEqual;
    }
    return false;
};
