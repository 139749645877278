import { Card } from "@/components/Card";
import { FDecimalBox, FormStateContext } from "@/components/form";
import { Field, Fields } from "@/components/form/Field";
import { HStack, VStack } from "@/components/layouts";
import { ComboBox, Label, MultiSelectBox } from "@/components/primitive";
import { useMappedStore } from "@/storeUtils";
import { mockReasons } from "@/views/ComposedLot/shared/sections/mockReasons";
import { SectionProps } from "@/views/ComposedLot/shared/store";
import { setСonditionsExecutionCost, setСonditionsReasons, setСonditionsServiceCost } from "@/views/ComposedLot/shared/store/lotInfo";
import React from "react";
import { useContext } from "react";

interface LifecycleConditionsSectionProps extends SectionProps {
    is223: boolean
}

export const LifecycleConditionsSection: React.FC<LifecycleConditionsSectionProps> = x => {
    const state = useContext(FormStateContext);
    const [reasons, execution, service] = useMappedStore(x.formStore, s => [s.info.conditionsReasons, s.info.conditionsExecutionCost, s.info.conditionsServiceCost]);

    return <Card>
        <Fields orientation={state === "readonly" ? "vertical" : "horizontal"}>
            <Field title="Причины заключения контракта жизненного цикла">
                {state === "readonly" 
                    ? <VStack>
                        {
                            reasons?.map(r => <div style={{fontSize: 14}} key={r}>{r}</div>)
                        }
                      </VStack> 
                    : <MultiSelectBox options={mockReasons.map(v => ({key: v, desc: v}))} values={reasons ?? []} onChange={v => setСonditionsReasons(v)} style={{marginLeft: -7}}/>}
            </Field>
            <Field title="Стоимость поставки товара или выполнения работы">
                <VStack spacing="10px">
                    <HStack spacing="15px" alignItems="center">
                        <FDecimalBox style={{width: "20%"}}
                                    value={execution}
                                    onChange={setСonditionsExecutionCost}/>
                        <Label preset="readonlyValue">
                            Российский рубль
                        </Label>
                    </HStack>
                </VStack>
            </Field>
            <Field title="Стоимость последующего обслуживания, эксплуатации, ремонта или утилизации">
                <VStack spacing="10px">
                    <HStack spacing="15px" alignItems="center">
                        <FDecimalBox style={{width: "20%"}}
                                    value={service}
                                    onChange={setСonditionsServiceCost}/>
                        <Label preset="readonlyValue">
                            Российский рубль
                        </Label>
                    </HStack>
                </VStack>
            </Field>
        </Fields>
    </Card>;
};