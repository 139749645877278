













































































































































import DataTable from "@/components/vue/DataTable.vue";
import Expander from "@/components/Expander.vue";
import { SimpleRow } from "@/components/vue/form-table/FormFields";
import FormTable from "@/components/vue/FormTable.vue";
import { ITableColumn, EditType } from "@/components/TableAbstractions";
import EventBus, { showModal } from "@/EventBus";
import { makeFormHelpers } from "@/form-declarations/helpers";
import { FinancialSourceTypeStrings, LawType } from "@/models/enums";
import Decimal from "decimal.js";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { IFieldProps } from "@/components/vue/form-table/FieldBase";
import { ContractFinancialSource, ContractFinancialSourceUsage, ContractStage } from "@/models/Contracts";
import AddStageFinances from "./AddStageFinances.vue";
import { ContractScope } from './ContractScope';
import { ConfirmationModal } from "@/views/Contracts/modals/ConfirmationModal";
import {isConcludedEshopContract} from "@/form-declarations/contractStageActions";
import { PaymentTermType, PaymentTermTypeStrings, ReviewDocumentsTermType, ReviewDocumentsTermTypeStrings, SendDocumentsTermType, SendDocumentsTermTypeStrings} from "@/models/ComposedLots/PaymentStageTerm";
import { addDays, subDays } from "date-fns";
import { toastError } from "@/components/toasts/toasts";

const h = makeFormHelpers<ContractStage>();

const sourceName = ({ source, account }: ContractFinancialSource) => {
    return [source.year, FinancialSourceTypeStrings[source.type], source.code(),
        account && account.accountNumber || ""].join("/");
};

const sourceEq = (a: ContractFinancialSource, b: ContractFinancialSource) => {
    return a.source.id === b.source.id &&
        ((a.account === null && b.account === null) ||
            (!!a.account && !!b.account && a.account.accountNumber === b.account.accountNumber));
};

interface StageSourceUsage {
    index: number
    name: string
    percent: Decimal
    available: Decimal
    usage: ContractFinancialSourceUsage
}

interface WrappedStage {
    stage: ContractStage
    expanded: boolean
}

@Component({ components: { Expander, FormTable, DataTable } })
export default class Stages extends Vue {
    @Prop() private props!: IFieldProps<SimpleRow<ContractScope>>;

    public isActionsLocked = isConcludedEshopContract(this.props.source.contract);
    private isConcludedEshopContract = isConcludedEshopContract(this.props.source.contract);

    public wrappedStages =
        this.updateStages((this.props.source as ContractScope).contract && (this.props.source as ContractScope).contract.newStages || []);

    @Watch("props.source.contract.newStages", { deep: true }) private updateStages(newStages: ContractStage[]): WrappedStage[] {
        //TODO: удалить try catch если ошибка при клике по "Добавить этап" не воспроизведется
        try {
            const oldStages = this.wrappedStages || [];
            newStages.forEach(s => {
                if (!s.endDate && s.startDate) {
                    s.endDate = addDays(s.startDate, s.paymentOffset ?? 0);
                }
                if(!s.sendDocumentsDate) {
                    s.sendDocumentsDate = this.onSendDocumentsDateChange(s.startDate);
                }
                if(!s.reviewDocumentsDate) {
                    s.reviewDocumentsDate = this.onReviewDocumentsDateChange(s.startDate);
                }
                if(!s.paymentDate) {
                    // s.paymentDate = this.onPaymentDocumentsDateChange(s.endDate);
                  s.paymentDate = s.endDate;
                }
            });
            return this.wrappedStages = (newStages || []).map(x => ({ stage: x, expanded: !!oldStages.find(o => o.stage === x && o.expanded) }));
        } catch (e) {
            console.error('Failed to recalculate stages!');
            console.log('data used to recalculate: oldStages: ', this.wrappedStages, 'newStages: ', newStages, 'props: ', this.props, 'context: ', this);
            console.error('original error: ', e);
            return this.wrappedStages || [];
        }
    }

    public get firstColumnWidth() {
        if(window.innerWidth > 2500) return "300px";
        if(window.innerWidth > 2000) return "200px";
        return "200px";
    }

    public get sumWidth() {
        if(window.innerWidth > 2500) return "292px";
        if(window.innerWidth > 2000) return "192px";
        return "200px";
    }

    public get sendDocumentsTermOptions() {
        return Object.keys(SendDocumentsTermTypeStrings).map(v => ({key: v, desc: SendDocumentsTermTypeStrings[v as SendDocumentsTermType] }));
    }

    public get reviewDocumentsTermOptions() {
        return Object.keys(ReviewDocumentsTermTypeStrings).map(v => ({key: v, desc: ReviewDocumentsTermTypeStrings[v as ReviewDocumentsTermType] }));
    }

    public get paymentTermOptions() {
        return Object.keys(PaymentTermTypeStrings).map(v => ({key: v, desc: PaymentTermTypeStrings[v as PaymentTermType] }));
    }

    public toggleStage(stage: WrappedStage) {
        for (const ws of this.wrappedStages)
            ws.expanded = ws === stage && !ws.expanded;
    }


    get hasTermDates() {
        return Boolean(this.props.source.contract.sendDocumentsTerm
               || this.props.source.contract.reviewDocumentsTerm
               || this.props.source.contract.paymentTerm);
    } 

    public onSendDocumentsDateChange = (v: Date | null) => {
        if(!v) return null;
        const firstDigitRegex = /(\d+)/;
        const term = this.props.source.contract.sendDocumentsTerm;
        if(!term) return v;
        const offset = term.match(firstDigitRegex)?.[0] ?? 0;
        return addDays(v, +offset);
    };

    public getSendDocumentsDisplayDate = (v: Date | null) => {
        if(!v) return null;
        const firstDigitRegex = /(\d+)/;
        const term = this.props.source.contract.sendDocumentsTerm;
        if(!term) return v;
        const offset = term.match(firstDigitRegex)?.[0] ?? 0;
        return subDays(v, +offset);
    };

    public onReviewDocumentsDateChange = (v: Date | null) => {
        if(!v) return null;
        const firstDigitRegex = /(\d+)/;
        const term = this.props.source.contract.reviewDocumentsTerm;
        if(!term) return v;
        const offset = term.match(firstDigitRegex)?.[0] ?? 0;
        return addDays(v, +offset);
    };

    public getReviewDocumentsDisplayDate = (v: Date | null) => {
        if(!v) return null;
        const firstDigitRegex = /(\d+)/;
        const term = this.props.source.contract.reviewDocumentsTerm;
        if(!term) return v;
        const offset = term.match(firstDigitRegex)?.[0] ?? 0;
        return subDays(v, +offset);
    };

    public onPaymentDocumentsDateChange = (v: Date | null) => {
        if(!v) return null;
        const firstDigitRegex = /(\d+)/;
        const term = this.props.source.contract.paymentTerm;
        const reviewOffset = this.props.source.contract.reviewDocumentsTerm.match(firstDigitRegex)?.[0] ?? 0;
        const sendOffset = this.props.source.contract.sendDocumentsTerm.match(firstDigitRegex)?.[0] ?? 0;
        if(!term) return v;
        const offset = term.match(firstDigitRegex)?.[0] ?? 0;
      return addDays(v, +offset + +reviewOffset + +sendOffset);
    };

    public getPaymentDocumentsDisplayDate = (v: Date | null) => {
        if(!v) return null;
        const firstDigitRegex = /(\d+)/;
        const term = this.props.source.contract.paymentTerm;
        if(!term) return v;
        const offset = term.match(firstDigitRegex)?.[0] ?? 0;
        return subDays(v, +offset);
    };

    private table: ITableColumn<StageSourceUsage>[] = [
        {
            title: "№",
            getter: src => src.index + 1,
            size: "auto"
        },
        {
            title: "Источник финансирования",
            getter: src => src.name,
        },
        {
            title: "% от суммы",
            type: EditType.DECIMAL,
            getter: src => src.percent,
        },
        {
            title: "​Сумма, руб.",
            getter: src => src.usage.stageAmount,
            type: EditType.DECIMAL,
        },
        {
            title: "​​В том числе авансовых платежей",
            getter: src => src.usage.advanceAmount,
            type: EditType.DECIMAL,
        },
    ];

    private showModal(stage: WrappedStage) {
        EventBus.callModal(AddStageFinances, {
            scope: this.props.source,
            stage: stage.stage,
            stages: this.wrappedStages
                .map(ws => ws.stage)
                .filter(s => s !== stage.stage)
        });
    }

    public async removeStage(stage: WrappedStage) {
        const confirmation = await showModal(ConfirmationModal, {title: "Удаление", text: "Вы уверены, что хотите удалить этап исполнения?"});
        if (!confirmation)
            return;
        const stages = (this.props.source as ContractScope).contract.newStages;
        const ix = stages.indexOf(stage.stage);
        if (ix !== -1) stages.splice(ix, 1);
    }

    public stageUsage(stage: WrappedStage, filter = true) {
        return stage.stage.stageSourceUsage.map((usage, index) => ({
            index,
            usage,
            name: sourceName(usage.source),
            percent: usage.stageAmount
                .div(usage.source.amount)
                .mul(100),
            available: null,
        }));
    }

    public stageAmount(stage: ContractStage) {
        return stage.stageSourceUsage.reduce((r, x) => x.stageAmount.isFinite() ? r.add(x.stageAmount) : r, new Decimal(0));
    }

    public advanceAmount(stage: ContractStage) {
        return stage.stageSourceUsage.reduce((r, x) => x.advanceAmount.isFinite() ? r.add(x.advanceAmount) : r, new Decimal(0));
    }

    public stageAmountPercent(stage: ContractStage) {
        return this.stageAmount(stage).div(this.props.source.contract.cost).mul(100).toNearest(0.01, Decimal.ROUND_DOWN);
    }

    public advanceAmountPercent(stage: ContractStage) {
        return this.advanceAmount(stage).div(this.props.source.contract.cost).mul(100).toNearest(0.01, Decimal.ROUND_DOWN);
    }
}
