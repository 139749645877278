import { Address, Specification, SupplyStage } from "@/models/ComposedLots/specifications";
import Decimal from "decimal.js";
import { makeAutoObservable, toJS } from "mobx";
import { MeasurementUnit } from "@/models";
import { DayType } from "@/models/enums";
import { addDays } from "date-fns";

export class SupplyStageForm {
    constructor() {
        makeAutoObservable(this);
    }
    
    stageType: "ABSOLUTE" | "RELATIVE" = "RELATIVE";
    hasChanges: boolean = false;

    originalStage: SupplyStage | null = null;
    fromContractConclusion: boolean = false;

    get isStageValid() {
        const validAbsolute = (this.fromContractConclusion ? true : !!this.startAbsolute) && !!this.endAbsolute;
        const validRelative = (!!this.startRelative || this.startRelative === 0) && (!!this.endRelative || this.endRelative === 0) && !!this.stageDayType && !!this.finishYear;
        const validCommon = !!this.stageVolume && !!this.stageAddress;
        return validCommon && (this.stageType === "ABSOLUTE" ? validAbsolute : validRelative);
    }

    get isStageAddressValid() {
        return !!this.stageAddress;
    }

    get isStageWithoutAddressValid() {
        //for assertion to show different errors in Vcheck
        const validAbsolute = (this.fromContractConclusion ? true : !!this.startAbsolute) && !!this.endAbsolute;
        const validRelative = (!!this.startRelative || this.startRelative === 0) && (!!this.endRelative || this.endRelative === 0) && !!this.stageDayType && !!this.finishYear;
        const validCommon = !!this.stageVolume && !this.stageVolume.isZero();
        return validCommon && (this.stageType === "ABSOLUTE" ? validAbsolute : validRelative);
    }

    refillStage = (s: SupplyStage) => {
        this.originalStage = s;
        this.stageType = s.type;
        if(s.type === "ABSOLUTE") {
            this.startAbsolute = s.startDate;
            this.endAbsolute = s.endDate;
            this.fromContractConclusion = s.fromContractConclusion ?? false;
        }
        if(s.type === "RELATIVE") {
            this.startRelative = s.startOffset;
            this.endRelative = s.endOffset;
            this.stageDayType = s.startOffsetType ?? s.endOffsetType;
            this.finishYear = s.finishYear ?? new Date().getFullYear();
        }

        this.stageVolume = s.volume;
        this.stageAddress = s.address;
    };

    get validatedStage(): SupplyStage {
        if(this.stageType === "ABSOLUTE") {
                return {
                    volume: this.stageVolume ?? new Decimal(0),
                    type: this.stageType,
                    conditions: this.originalStage?.conditions ?? "",
                    address: this.stageAddress!,
                    fromContractConclusion: this.fromContractConclusion ?? false,
                    startDate: this.fromContractConclusion ? null : this.startAbsolute ?? new Date(),
                    endDate: this.endAbsolute ?? addDays(new Date(), 1)
                };
            }
        return {
            volume: this.stageVolume ?? new Decimal(0),
            type: this.stageType,
            conditions: this.originalStage?.conditions ?? "",
            address: this.stageAddress!,
            startOffset: this.startRelative ?? 0,
            startOffsetType: this.stageDayType,
            endOffset: this.endRelative ?? 0,
            endOffsetType: this.stageDayType,
            finishYear: this.finishYear ?? new Date().getFullYear(),
        };
    }

    get showStageErrors() {
        if(this.stageType === "RELATIVE")
            return !(this.endRelative && this.startRelative && this.endRelative > +this.startRelative) || !this.endRelative;
    }

    get paddingForErrors() {
        if(this.stageType === "RELATIVE")
            if(!(this.endRelative && this.startRelative && this.endRelative > +this.startRelative)) {
                return 60;
            }
            if(!this.endRelative || !this.stageVolume) return 28;
        return 10;
    }

    stageVolume: Decimal | null = null;
    stageDayType: DayType = DayType.NORMAL;

    startRelative: number | null = null;
    endRelative: number | null = null;
    finishYear: number | null = null;

    startAbsolute: Date | null = null;
    endAbsolute: Date | null = null;

    stageAddress: Address | null = null;
}

export class SpecificationForm {
    constructor() {
        makeAutoObservable(this);
    }

    wasSpecChanged: boolean = false;

    get hasChanges() {
        return this.supplyStages.some(v => v.hasChanges) || this.wasSpecChanged;
    }

    refill = (v: Specification) => {

        this.specification = v;
        this.specType = v.supplyStages[0]?.type ?? "RELATIVE";
        v.supplyStages.forEach(s => {
            const result = new SupplyStageForm();
            result.refillStage(s);
            //probably wrong, needs update with stageReplace?
            this.supplyStages.push(result);
        });
        this.volume = v.volume;
        this.specId = v.id;
        this.startPriceVolume = v.startPrice?.total ?? new Decimal(0);
        this.specMeasurementUnit = v.measurementUnit;
        this.additionalChars = v.additionalCharacteristicJustification;
        // ....
    };

    deleteStage = (idx: number) => {
        this.supplyStages = this.supplyStages.filter((stage, i) => i !== idx);
        this.wasSpecChanged = true;
    };

    clearStages = () => {
        this.wasSpecChanged = true;
        this.supplyStages = [];
    };

    getEmptyStage = (type: "ABSOLUTE" | "RELATIVE"): SupplyStage => {
        if(type === "ABSOLUTE") {
            return {
                volume: new Decimal(0),
                type,
                conditions: "",
                address: null!,
                fromContractConclusion: false,
                startDate: new Date(),
                endDate: addDays(new Date(), 1)
            };
        } else {
            return {
                volume: this.volume ?? this.specification?.volume  ?? new Decimal(0),
                type,
                conditions: "",
                address: null!,
                startOffset: 0,
                startOffsetType: DayType.NORMAL,
                endOffset: 0,
                endOffsetType: DayType.NORMAL,
                finishYear: new Date().getFullYear(),
            };
        }
    };

    addSupplyStage = () => {
        if(this.supplyStages.length) {
            const newStage = this.supplyStages[this.supplyStages.length - 1];
            const newStageCopy = new SupplyStageForm();
            newStageCopy.refillStage(newStage.validatedStage!);
            const stagesVolumeSum = this.supplyStages.reduce((sum, stage) => sum.add(stage.stageVolume ?? new Decimal(0)), new Decimal(0));
            if(this.volume) {
                if(stagesVolumeSum.greaterThan(this.volume)) {
                    newStageCopy.stageVolume = new Decimal(0);
                } else {
                    newStageCopy.stageVolume = this.volume.sub(stagesVolumeSum);
                }
            }
            this.supplyStages.push(newStageCopy);
        } else {
            const newStage = new SupplyStageForm();
            newStage.refillStage(this.getEmptyStage(this.specType));
            this.supplyStages.push(newStage);
        }
        this.wasSpecChanged = true;
    };

    supplyStages: SupplyStageForm[] = [];
    specification: Specification | null = null;

    specType: "RELATIVE" | "ABSOLUTE" = "RELATIVE";
    specId: string = "";
    volume: Decimal | null = null;
    specMeasurementUnit: MeasurementUnit | null = null;
    startPriceVolume: Decimal | null = null;
    additionalChars?: string;
    dayType: "WORK" | "CALENDAR" = "CALENDAR";

    get isSpecValid() {
        return !!this.specification?.volume 
            && !this.specification.volume.isZero()
            && !!this.specification.characteristics 
            && !!this.specification.subjectDeclaration
            && !!this.specification.measurementUnit;
    }

    get isVolumeValid(): boolean {
        if(!this.specification || !this.volume) return false;
        const stagesVolume = this.supplyStages.reduce((sum, stage) => sum.add(stage.stageVolume ?? new Decimal(0)), new Decimal(0));
        return stagesVolume.eq(this.volume);
    }

    get validated(): Specification {

        return {
            actionClass: this.specification?.actionClass,
            additionalCharacteristicJustification: this.specification?.additionalCharacteristicJustification,
            characteristics: this.specification?.characteristics!,
            id: this.specification?.id ?? "",
            measurementUnit: this.specification?.measurementUnit!,
            startPrice: this.specification?.startPrice,
            subjectDeclaration: this.specification?.subjectDeclaration!,
            supplyAddress: this.specification?.supplyAddress,
            supplyStages: this.supplyStages.map(s => s.validatedStage),
            volume: this.specification?.volume ?? new Decimal(0)
        };
    }


    get subjectDeclaration() {
        return this.validated?.subjectDeclaration;
    }

    get startPrice() {
        return this.validated?.startPrice;
    }

    changeType = (type: "RELATIVE" | "ABSOLUTE") => {
        this.specType = type;
        this.supplyStages.forEach(s => {
            s.stageType = type;
            s.hasChanges = true;
        });
        this.wasSpecChanged = true;
    };
}

// mobx store for specs
export const LotSpecifications = new class {
    constructor() {
        makeAutoObservable(this);
    }


    get hasChanges() {
        return this.specificationForms.some(v => v.hasChanges);
    }

    specificationForms: SpecificationForm[] = [];
    productSpecificationForms: SpecificationForm[] = [];

    replace = (v: Specification[]) => {
        this.specificationForms = v.map(spec => {

            const result = new SpecificationForm();

            result.refill(spec);

            return result;
        });

        this.onUpdateSpecification?.() ?? null;
    };

    replaceProducts = (v: Specification[]) => {
        this.productSpecificationForms = v.map(spec => {
            const result = new SpecificationForm();

            result.refill(spec);

            return result;
        });
        void this.onUpdateSpecification?.(true);
    };

    addOrEditSpecification = (s: Specification) => {
        
        const found = this.specificationForms.findIndex(v => v.specId === s.id);
        if (found === -1) {
            const newSpec = new SpecificationForm();
            newSpec.refill(s);
            newSpec.wasSpecChanged = true;
            this.specificationForms = this.specificationForms.concat(newSpec);
        } else {
            const copy = [...this.specificationForms];
            copy[found] = new SpecificationForm();
            copy[found].refill(s);
            copy[found].wasSpecChanged = true;
            this.specificationForms = copy;
        }

        this.onUpdateSpecification?.(false) ?? null;
    };

    remove = (s: string[]) => {
        this.specificationForms = this.specificationForms.filter(i => !s.includes(i.specId));
        this.onUpdateSpecification?.();
    };

    onUpdateSpecification: ((isProductSpec?: boolean) => void) | null = null;
};

export const getTotalStartPricesAmount = (specs: SpecificationForm[]) =>
    specs.reduce((p, v) => p.add(v.startPrice?.total.mul(v.startPrice.ratio) ?? 0), new Decimal(0));
