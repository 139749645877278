import React from 'react';
import { Button, Label, Note, Error } from "@/components/primitive";
import { Expandee, HStack, VGrid, VStack } from "@/components/layouts";
import {
    Table, useOptionalStoredRemoteDataset
} from "@/components/table";
import router from "@/router";
import { Field, FieldGrid, FieldInset, FieldStack } from "@/components/form/Field";
import { FileInput } from "@/components/primitive/FileInput";
import { Card } from "@/components/Card";
import { LawTypeStrings } from "@/models/enums";
import { formatNumber } from "@/NumberFormatting";
import { Store } from "effector";
import { PlanState, setPlan } from "@/views/Plans2020/view/store";
import { useStore } from "effector-react";
import { PlanStatusStrings } from "@/models/Plans/PlanStatus";
import { LotsTable } from "@/views/Plans2020/shared/LotsTable";
import { useMappedStore } from "@/storeUtils";
import { approvePlan, deletePlan, publishPlan, sendPlanToFo } from "@/api/Plans2020";
import { useErrorHandler } from "@/reactUtils";
import { ModalFC, showModal } from "@/EventBus";
import { CardModal } from "@/components/modals/CardModal";
import moment from "moment";
import { AuthorizationEisModal } from "@/views/Plans2020/view/AuthorizationEisModal";
import { HistoryItem } from "@/models";

const InfoRow = (x: { title: string; value: number; percent?: number; redPercent?: boolean; error?: string }) => {
    return <Field title={x.title}>
        <VStack spacing="5px">
            <VGrid columns="200px 200px auto">
                <Label preset="medium">{formatNumber(x.value)}</Label>
                <Label preset="medium">Российский рубль</Label>
                { x.percent !== undefined && <Label preset="medium" style={x.redPercent ? { color: "red" } : undefined}>({x.percent}%)</Label> }
            </VGrid>
            {
                x.error && <Label preset="readonlyValue" style={{ color: "red" }}>{x.error}</Label>
            }
        </VStack>
    </Field>;
};

const NoEisModal: ModalFC = x => {
    const bottom = <HStack reverse>
        <Button onClick={x.done} title="Закрыть" />
    </HStack>;

    return <CardModal title="Не поддерживается" close={x.done} width="40%" bottom={bottom}>
        Связывание с ЕИС не поддерживается в учебной версии
    </CardModal>;
};

export const ViewPlan = (x: { storeContainer: { store: Store<PlanState> } }) => {
    const { plan } = useStore(x.storeContainer.store);

    const storedRds = useMappedStore(x.storeContainer.store, x => x.lotsDataset);
    const history = useMappedStore(x.storeContainer.store, x => x.history);
    const rds = useOptionalStoredRemoteDataset(storedRds);

    const [error, makeSafe] = useErrorHandler();

    const approve = makeSafe(async () => {
        if (!plan) return;
        setPlan(await approvePlan(plan.id));
    });

    const sendToFo = makeSafe(async () => {
        if (!plan) return;
        setPlan(await sendPlanToFo(plan.id));
    });

    const publish = makeSafe(async () => {
        if (!plan) return;

        plan?.law === "F223" && await showModal(AuthorizationEisModal);
        setPlan(await publishPlan(plan.id));
    });

    const remove = makeSafe(async () => {
        if (!plan) return;

        await deletePlan(plan.id);
        router.resetTo("/plans/2020");
    });

    const showNoEisModal = () => {
        showModal(NoEisModal).ignore;
    };

    const currentYear: number = new Date().getFullYear();

    return <VStack outerStyle={{ margin: "30px" }} spacing="15px">
        <Error object={error}/>
        <Note preset="title">{
            // plan?.law === "F44" ? "План-график закупок" : "План закупок"
            "План-график закупок"
        }</Note>
        <Note preset="title">
            <HStack spacing="15px">
                <Button icon="aPrint" disabled/>
                { plan?.status === "CREATING" && <Button title="утвердить" onClick={approve}/> }
                {
                    plan?.law === "F44" && <>
                        { plan?.status === "APPROVED" && <Button color="green" title="на согласование в фо" onClick={sendToFo}/> }
                        { plan?.status === "FO_AGREED" && <Button title="отправить на публикацию" onClick={publish}/> }
                    </>
                }
                {
                    (plan?.law === "F223" && plan?.status === "APPROVED") && <>
                        { <Button color="green" title="отправить на публикацию" onClick={publish}/> }
                        { <Button color="red" title="связать план-график закупок с еис" onClick={showNoEisModal} /> }
                    </>
                }
                <Expandee/>
                { plan?.status === "APPROVED" || plan?.status === "CREATING" && <Button title="удалить" color="red" onClick={remove}/> }
                <Button color="red" icon="aClose" onClick={() => router.resetTo("/plans/2020")}/>
            </HStack>
        </Note>
        <Card title="Детализация">
            <VStack spacing="15px">
                <VGrid columns="1fr 1fr">
                    <FieldStack>
                        <Field title="Закон-основание">
                            <Label>{plan && LawTypeStrings[plan.law]}</Label>
                        </Field>
                        <Field title="Заказчик">
                            <Label>??</Label>
                        </Field>
                        <Field title={plan?.law === "F44" ? "Период" : "Год"}>
                            <Label>{plan?.law === "F44" ? `${plan.periodStartYear}-${plan.periodStartYear + 2}` : plan?.periodStartYear }</Label>
                        </Field>
                        <Field title="Статус">
                            <Label>{plan && PlanStatusStrings[plan.status]}</Label>
                        </Field>
                    </FieldStack>
                    <FieldStack>
                        <Field title="Версия плана">
                            <Label>{plan?.orderNumber}</Label>
                        </Field>
                        <Field title="Версия согласования">
                            <Label>??</Label>
                        </Field>
                    </FieldStack>
                </VGrid>
                {
                    plan && (plan.law === "F223" ? <FieldGrid>
                        <Field title="Совокупный годовой стоимостной объём закупок, планируемых к осуществлению:">
                            <Label style={{ textAlign: "right", marginRight: "150px" }}>0,00 Российский рубль</Label>
                        </Field>
                        <Field title="Объём закупок, исключённых из расчёта совокупного годового стоимостного объёма:">
                            <Label style={{ textAlign: "right", marginRight: "150px" }}>0,00 Российский рубль</Label>
                        </Field>
                        <FieldInset>
                            <Table dataset={["item?"]} hideHeaders columns={[
                                Table.Column("", () => <>Планируемый объём закупок у субъектов МСП:</>, { width: "4fr" }),
                                Table.Column("", () => <>0,00</>),
                                Table.Column("", () => <><b>0,00 %</b></>)
                            ]}/>
                        </FieldInset>
                        <Field title="Совокупный годовой стоимостной объём договоров:">
                            <Label style={{ textAlign: "right", marginRight: "150px" }}>0,00 Российский рубль</Label>
                        </Field>
                        <FieldInset>
                            <Table dataset={[false, true]} hideHeaders columns={[
                                Table.Column("", x => x.item
                                    ? <>Годовой объём закупок только среди субъектов МСП:</>
                                    : <>Годовой объём закупок у субъектов МСП:</>, { width: "4fr" }),
                                Table.Column("", () => <>0,00</>),
                                Table.Column("", () => <><b>0,00 %</b></>)
                            ]}/>
                        </FieldInset>
                        <Field title="Описание изменений, внесенных в план закупок">
                            <FileInput onChange={() => {}}/>
                        </Field>
                    </FieldGrid> : <FieldStack>
                        <InfoRow title="Совокупный годовой объем закупок" value={87393750} />
                        <InfoRow title="Общая сумма" value={9700000} />
                        <InfoRow title="Сумма закупок к размещению СМП" value={0} percent={0} redPercent
                                 error="Не менее 25% совокупного годового объёма должно быть запланировано к размещению среди СМП (44-ФЗ, Ст. 30, ч. 1)."/>
                        <InfoRow title="Сумма закупок инновационной продукции" value={0} percent={0}/>
                        <InfoRow title="Сумма закупок методом запроса котировок" value={0} percent={0}/>
                        <InfoRow title="Сумма закупок у единственного поставщика по пункту 4 части 1 статьи 93" value={0} percent={0}/>
                        <InfoRow title="Сумма закупок у единственного поставщика по пункту 5 части 1 статьи 93" value={0} percent={0}/>
                    </FieldStack>)
                }
            </VStack>
        </Card>
        <LotsTable rds={rds} />
        <Card title="Обмен с АСУ ГФ">
            <Table dataset={[""]} columns={[
                Table.AutoColumn("№",
                    () => <>1</>),
                Table.Column("Исходящий файл",
                    () => <>Request.xml</>),
                Table.Column("Дата отправки",
                    () => <>18.01.{currentYear} 14:08:35</>),
                Table.Column("Подтверждение получения",
                    () => <>Ticket.xml</>),
                Table.Column("Дата приема подтверждения",
                    () => <>18.01.{currentYear} 14:08:58</>),
                Table.Column("Сведения о согласовании",
                    () => <>Protocol.xml</>),
                Table.Column("Дата получения сведений",
                    () => <>18.01.{currentYear} 14:09:28</>),
                Table.Column("Дополнительные сведения о согласовании",
                    () => <>PositiveProtocol.xml</>),
                Table.Column("Дата получения дополнительных сведений",
                    () => <>18.01.{currentYear} 14:09:28</>),
            ]}/>
        </Card>
        <Card title="Обмен с ЕИС">
            <Table dataset={[""]} columns={[
                Table.AutoColumn("Номер",
                    () => <>1</>),
                Table.Column("Исходящий файл",
                    () => <>xml_to_oos.xml</>),
                Table.Column("Дата отправки",
                    () => <>18.01.{currentYear} 14:08:35</>),
                Table.Column("Подтверждение получения",
                    () => <>xml_from_oos.xml</>),
                Table.Column("Дата приема подтверждения",
                    () => <>18.01.{currentYear} 14:08:58</>),
                Table.Column("Сведения о публикации",
                    () => <>xml_from_oos_publ.xml</>),
                Table.Column("Дата получения сведений",
                    () => <>18.01.{currentYear} 14:09:28</>)
            ]}/>
        </Card>
        <Card hasCollapseButton title={<HStack>
            <span>История изменений</span>
        </HStack>}>
            <VStack spacing={"15px"}>
                <Table<HistoryItem> dataset={history} columns={[
                    Table.AutoColumn("Пользователь", x => <>{x.item.user.fullName}</>),
                    Table.AutoColumn("Дата и время изменения", x => <>{moment(x.item.date).format("DD.MM.YYYY HH:mm")}</>),
                    Table.AutoColumn("Статус", x => <>{x.item.status}</>),
                ]}/>
            </VStack>
        </Card>;

    </VStack>;
};
