






















































































import { Component, Vue, Prop } from "vue-property-decorator";
import { showModal } from "@/EventBus";
import Auth, { setSelectedParticipant } from '@/api/Auth';
import router from '@/router';
import { User } from '@/models/User';
import Admin from '@/api/Admin';
import { UserParticipantSelectionModal } from "@/views/modals/UserParticipantSelectionModal/UserParticipantSelectionModal";

@Component
export default class Header extends Vue {
    @Prop() public user!: User | null;

    public userPopupVisible = false;

    public get yearSelected() { return Auth.selectedYear }
    public set yearSelected(y) { Auth.selectedYear = y }

    public yearSelectorVisible = false;

    public hideYearSelector() {
        this.yearSelectorVisible = false;
    }

    public get years() {
        const currentYear = new Date().getFullYear();
        const result: number[] = new Array(5).fill(currentYear);
        return result.map((year, idx) => year - idx).reverse();
    }

    public logout() {
        this.userPopupVisible = false;
        Auth.logout();
        router.resetTo("/login");
    }

    public async depersonate() {
        this.userPopupVisible = false;
        await Admin.depersonate();
        router.resetTo("/");
        window.location.reload();
    }

    public goToWebinarsPage() {
        window.location.href = window.location.origin + "/webinars/";
    }

    public goToAdminPage() {
        this.userPopupVisible = false;
        router.push("/admin");
    }

    public async selectParticipant() {
        const userId = Auth.currentUserInfo?.id;
        if (!userId) return;
        const allowedParticipants = Auth.currentUserInfo?.allowedParticipants ?? [];
        const participant = await showModal(UserParticipantSelectionModal, { allowed: allowedParticipants });
        if (!participant) return;
        const updatedUser = await setSelectedParticipant(userId, participant.id);
        if (updatedUser) Auth.currentUserInfo = updatedUser;
        window.location.reload();
    }

    public selectYear(year: number) {
        this.yearSelectorVisible = false;
        this.yearSelected = year;
        window.location.reload();
    }
}
