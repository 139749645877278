import { createEvent, createStore, Store } from "effector";
import Decimal from "decimal.js";
import { BankAccount } from "@/models/BankAccount";
import { ProviderSelectionType, ProviderSelectionType223Strings } from "@/models/ComposedLots";
import { LotForm } from "@/views/ComposedLot/shared/store/index";
import { setProviderSelectionType, setSingleProviderReasonId } from "@/views/ComposedLot/shared/store/lotInfo";
import { LotProvisionTemplate } from "@/models/ComposedLots/LotProvision";
import { setInfoChanged } from "@/views/ComposedLot/shared/store/head";
import { vAssert, vNotEmptyWhen } from "@/api/validation";
import { LawType } from "@/models/enums";
import { AggregationSessionModal } from "@/views/ComposedLot/shared/sections/modals/AggregationSessionModal";
import { showModal } from "@/EventBus";


const ordersDefaults = {
    request: (is223?: boolean) => is223 ? "" : "В соответствии с правилами и порядком, определенными оператором электронной торговой площадки с использованием специального счета, открытого участником закупки в одном из банков, перечень которых утвержден распоряжением Правительства РФ от 13 июля 2018 г. № 1451-р «Об утверждении перечня банков в соответствии с ч. 10 ст. 44 и ч. 5 ст. 84.1 Федерального закона от 5 апреля 2013 г. № 44-ФЗ»",
    contract: (is223?: boolean) => is223 ? "" : "Контракт заключается после предоставления заказчику обеспечения исполнения контракта. Обеспечение исполнения контракта может быть представлено в виде безотзывной независимой гарантии или внесением денежных средств на указанный заказчиком счет, на котором в соответствии с законодательством Российской Федерации учитываются операции со средствами, поступающими заказчику, в размере обеспечения исполнения контракта, указанном в документации о закупках. Безотзывная независимой гарантия должна соответствовать требованиям, установленным Гражданским кодексом Российской Федерации, а также иным законодательством Российской Федерации. Способ обеспечения исполнения контракта определяется участником закупки, с которым заключается контракт, самостоятельно. Срок внесения обеспечения - до момента заключения контракта»",
    guarantee: () => ""
};

export interface LotProvisions {
    request: LotProvisionF
    contract: LotProvisionF
    guarantee: LotProvisionF
}

export interface LotProvisionF {
    used: boolean
    minimalTerm?: number
    percent?: Decimal
    account?: BankAccount
    specialOrder: boolean
    executionCostPercent?: Decimal
    serviceCostPercent?: Decimal
    order?: string
    acceptor?: string

    // https://rt.rtall.ru/redmine/issues/803
    chargedWarranty?: boolean // Устанавливаются требования к гарантии качества товаров, работ, услуг
    warrantyServiceReq?: string // Информация о требованиях к гарантийному обслуживанию товаров
    manufacturersWarrantyReq?: string // Требования к гарантии производителя товара
    warrantyTerm?: string // Срок, на который предоставляется гарантия и (или) требования к объему предоставления гарантий качества товара, работы, услуги *
}

export type ProvisionKind = ("request" | "contract" | "guarantee") & keyof LotProvisions;

export const setUsed = createEvent<[ProvisionKind, boolean]>();
export const setSpecialOrder = createEvent<[ProvisionKind, boolean]>();
export const setOrder = createEvent<[ProvisionKind, string]>();
export const setAcceptor = createEvent<[ProvisionKind, string]>();
export const setPercent = createEvent<[ProvisionKind, Decimal | undefined]>();
export const setExecutionCostPercent = createEvent<[ProvisionKind, Decimal | undefined]>();
export const setServiceCostPercent = createEvent<[ProvisionKind, Decimal | undefined]>();
export const setMinimalTerm = createEvent<[ProvisionKind, number | undefined]>();
export const setDefaultOrderValue = createEvent<[ProvisionKind, boolean | undefined]>();
export const setAccount = createEvent<[ProvisionKind, BankAccount | undefined]>();
// https://rt.rtall.ru/redmine/issues/803
export const setChargedWarranty = createEvent<[ProvisionKind, boolean | undefined]>();
export const setWarrantyServiceReq = createEvent<[ProvisionKind, string | undefined]>();
export const setManufacturersWarrantyReq = createEvent<[ProvisionKind, string | undefined]>();
export const setWarrantyTerm = createEvent<[ProvisionKind, string | undefined]>();

export type ProvisionRequirement = "not-required" | "forbidden";

export const getRequirement = (provision: ProvisionKind, psType: ProviderSelectionType, year?: number): ProvisionRequirement => {
    switch (provision) {
        case "guarantee": return "not-required";
        case "request": switch (psType) {
            case "SINGLE_PROVIDER_PURCHASE":
            case "E_OFFERS_REQUEST":
                return "forbidden";
            case "E_QUOTATIONS_REQUEST":
                return year && year > 2021 ? "not-required" : "forbidden";
            default:
                return "not-required";
        }
        case "contract": return "not-required";
    }
};

const replace = createEvent<LotProvisions>("replace lot provisions");
const reset = createEvent("reset lot provisions");
/*  
    Проблема - несколько AggregationSessionModal показываются сразу, накладываясь друг на другаю
    Решение -  починить создание нескольких сторов или ресет, или апдейт.
    Временное решение - смотреть на флаг debounce и если он true - игнорировать другие модалки.
    debounce вынесен наверх, чтобы всем сторам/обсерверам было видно флаг.
    Показ модалок обработан в observeOnCombinedStore => setSingleProviderReasonId вотчер
    Воспроизведение:
    1) Нажать создать лот 223 ФЗ
    2) Способ определения поставщика - Закупка у единственного поставщика
    3) Основание заключения договора с единственным поставщиком - последний пункт (...,стоимость которых
       не превышает 600тыс. рублей...)
    4) Закрыть модалку
    5) Нажать на красный крестик закрытия либо в сайдбаре на другой пункт меню
    6) Повторить пункт 1-5 еще раз. С каждым разом будет на 1 модалку больше
*/
let debounce = false;

export const ProvisionsStore = {
    createStore: (): Store<LotProvisions> => {
        const spp = <K extends keyof LotProvisionF>(k: K) => (p: LotProvisions, [pn, v]: [ProvisionKind, LotProvisionF[K]]): LotProvisions =>
            ({...p, [pn]: {...p[pn], [k]: v}});

        const store = createStore<LotProvisions>({
            request: {
                used: false,
                specialOrder: false,
                order: "",
                acceptor: "",
                percent: new Decimal(0),
            },
            guarantee: {
                used: false,
                specialOrder: false,
                order: "",
                acceptor: "",
                minimalTerm: 0,
                percent: new Decimal(0),
            },
            contract: {
                used: false,
                specialOrder: false,
                order: "",
                acceptor: "",
                percent: new Decimal(0),
                executionCostPercent: new Decimal(0),
                serviceCostPercent: new Decimal(0)
            },
        }).on(setUsed, spp("used"))
            .on(replace, (_, l) => l)
            .reset(reset)
            .on(setSpecialOrder, spp("specialOrder"))
            .on(setOrder, spp("order"))
            .on(setAcceptor, spp("acceptor"))
            .on(setMinimalTerm, spp("minimalTerm"))
            .on(setPercent, spp("percent"))
            .on(setExecutionCostPercent, spp("executionCostPercent"))
            .on(setServiceCostPercent, spp("serviceCostPercent"))
            .on(setChargedWarranty, spp("chargedWarranty"))
            .on(setWarrantyServiceReq, spp("warrantyServiceReq"))
            .on(setManufacturersWarrantyReq, spp("manufacturersWarrantyReq"))
            .on(setWarrantyTerm, spp("warrantyTerm"))
            .on(setDefaultOrderValue, (x, [k, is223]) => {
                return {
                    ...x,
                    [k]: {
                        ...x[k],
                        order: ordersDefaults[k](is223)
                    }
                };
            })
            .on(setAccount, (x, [k, v]) => {
                return {
                    ...x,
                    [k]: {
                        ...x[k],
                        account: v,
                        acceptor: v?.receiver ?? ""
                    }
                };
            });

        store.watch(setSpecialOrder, (s, p) => setInfoChanged(true));
        store.watch(setOrder, (s, p) => setInfoChanged(true));
        store.watch(setAcceptor, (s, p) => setInfoChanged(true));
        store.watch(setPercent, (s, p) => setInfoChanged(true));
        store.watch(setExecutionCostPercent, (s, p) => setInfoChanged(true));
        store.watch(setServiceCostPercent, (s, p) => setInfoChanged(true));
        store.watch(setMinimalTerm, (s, p) => setInfoChanged(true));
        store.watch(setAccount, (s, p) => setInfoChanged(true));
        store.watch(setChargedWarranty, () => setInfoChanged(true));
        // auto uncheck "used" if it's not shown
        store.watch(setChargedWarranty, (_s, p) => p[1] ? void 0 : setUsed(["guarantee", false]));
        store.watch(setWarrantyServiceReq, () => setInfoChanged(true));
        store.watch(setManufacturersWarrantyReq, () => setInfoChanged(true));
        store.watch(setWarrantyTerm, () => setInfoChanged(true));

        // DEBUG
        store.watch((s) => {
            console.log("[provision] store changed, new value: ", s);
        });
        store.watch(reset, (s) => {
            console.log("[provision] store reset", s);
        });
        store.watch(replace, (s, p) => {
            console.log("[provision] store replace", s, p);
        });

        return store;
    },

    replace: replace,
    reset: reset,
    observeOnCombinedStore: (combinedStore: Store<LotForm>) => {
        combinedStore.watch(setUsed, (_, p) => {
            console.warn(`[provision] SET USED CALLED: ${p}`);
        });
        combinedStore.watch(setProviderSelectionType,  (s, pst) => {
            if (pst === undefined) return;

            // if (pst === "QUOTATION_SESSION") {
            //     showModal(AggregationSessionModal);
            // }

            setUsed([ "contract", s.provisions.contract.used && getRequirement("contract", pst) !== "forbidden" ]);
            setUsed([ "request", s.provisions.request.used && getRequirement("request", pst) !== "forbidden" ]);
            setUsed([ "guarantee", s.provisions.guarantee.used && getRequirement("guarantee", pst) !== "forbidden" ]);
        });
        combinedStore.watch(setSingleProviderReasonId, (_, id) => {
            // TODO: Починить стор вместо использования флага дебаунса с таймаутом
            // Временный фикс показа нескольких модалок вместо одной
            if(debounce) {
                // если флаг true, то не показываем лишние модалки
                console.warn('rendered extra modal, returning');
                return;
            } else {
                // иначе если нам надо показать модалку
                if (id === 1 || id === 2) {
                    // сообщаем остальным, что модалка уже показывается
                    debounce = true;
                    // 300мс должно хватить для обработки всех модалок вотчерами
                    // Таймаут введен для повторного показа модалки если пользователь вызовет иветн снова
                    setTimeout(() => {debounce = false} ,300);
                    // показываем модалку
                    showModal(AggregationSessionModal);
                }
            }
        });
    }
};

export const provisionToTemplate = (p: LotProvisionF, law?: LawType, draft?: boolean): LotProvisionTemplate => {

    const asserted = draft ? { minimalTerm: undefined } : vAssert({
        minimalTerm: vNotEmptyWhen(p.used && law === "F223", p.minimalTerm, "Заполните поле \"Минимальный срок гарантийных обязательств\""),
    });

    return {
        used: p.used,
        acceptor: p.acceptor,
        additionalInfo: p.specialOrder ? p.order : undefined,
        percent: p.percent ?? new Decimal(0),
        executionCostPercent: p.executionCostPercent ?? new Decimal(0),
        serviceCostPercent: p.serviceCostPercent ?? new Decimal(0),
        minimalTerm: asserted.minimalTerm,
        accountId: p.account?.id,

        chargedWarranty: p.chargedWarranty,
        warrantyServiceReq: p.warrantyServiceReq,
        manufacturersWarrantyReq: p.manufacturersWarrantyReq,
        warrantyTerm: p.warrantyTerm,
    };
};
