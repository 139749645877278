import { VStack } from "@/components/layouts";
import { Column, Table } from "@/components/table/Table";
import { Address, DtoSupplyStage, SupplyStage, formatAddress } from "@/models/ComposedLots/specifications";
import React, { useMemo, useState } from "react";
import { Button, ContextMenuHolder } from "@/components/primitive";
import { Card } from "@/components/Card";
import { Store } from "effector";
import { setSupplyStages, SpecificationStore } from "@/modal-views/PickSpecificationModal/store";
import { useMappedStore } from "@/storeUtils";
import { showModal, typifyVueModal } from "@/EventBus";
import { DayType, SupplyStagePeriodType } from "@/models/enums";
import { ISupplyStageModalArgs } from "@/views/modals/ModalArgs";
import PurchaseObjectDetailedAddStageModal
    from "@/views/modals/PurchaseObjectDetailedAddStageModal/PurchaseObjectDetailedAddStageModal.vue";
import { replaceOrPush } from "@/arrayUtils";
import { SupplyStage as SupplyStageOld } from "@/models";
import { IDtoSupplyStage } from "@/models/json";
import Decimal from "decimal.js";
import { applyN } from "@/models/parsing";
import { suppressEvent } from "@/reactUtils";
import { PickAddressModal } from "@/modal-views/PickAddressModal";
import {toJS} from "mobx";
import _ from "lodash";

export const formatDate = (stage: SupplyStage, end: boolean): string => {
    switch (stage.type) {
        case "RELATIVE":
            const offset = end ? stage.endOffset : stage.startOffset;
            const type = end ? stage.endOffsetType : stage.startOffsetType;
            const typeStr = type === DayType.NORMAL ? "календарных" : "рабочих";
            return `${offset} ${typeStr} дней с момента заключения контракта`;
        case "ABSOLUTE":
            return end
                ? stage.endDate.toLocaleDateString()
                : stage.startDate?.toLocaleDateString() ?? "С даты заключения контракта";
    }
};

// b-level cheats
const convertSupplyStageFromOld = (s: SupplyStageOld): SupplyStage =>
    SupplyStage.fromDto(s.toJson() as DtoSupplyStage);

const convertSupplyStageToOld = (s: SupplyStage): SupplyStageOld =>
    SupplyStageOld.fromJson(SupplyStage.toDto(s) as IDtoSupplyStage);

const stageModal = typifyVueModal<
    { stage?: SupplyStage; volume: Decimal; requiredPeriodType?: SupplyStagePeriodType; perItem?: boolean },
    SupplyStage,
    ISupplyStageModalArgs,
    SupplyStageOld>(
        PurchaseObjectDetailedAddStageModal,
        x => ({
            stage: x.stage ? convertSupplyStageToOld(x.stage) : null,
            requiredPeriodType: x.requiredPeriodType ?? null,
            purchasePerUnit: x.perItem,
            volume: x.volume,
            immutable: true
        }),
        convertSupplyStageFromOld);

const addOrEditSupplyStage = async (supplyStages: SupplyStage[], volume: Decimal = new Decimal(0), existing?: SupplyStage, perItem?: boolean) => {
    const requiredPeriodType = supplyStages[0]?.type;

    const result = await showModal(stageModal, { stage: existing ? _.cloneDeep(existing) : existing, requiredPeriodType, perItem, volume });
    if (result) {
        setSupplyStages(replaceOrPush(supplyStages, existing, result));
    }
};

const removeSupplyStage = (supplyStages: SupplyStage[], stage: SupplyStage) => setSupplyStages(supplyStages.filter(x => x !== stage));

export const SupplyStages = (x: { store: Store<SpecificationStore>; readonly?: boolean; perItem?: boolean }) => {
    const [supplyStages, purchaseVolume = new Decimal(0)] = useMappedStore(x.store, x => [x.supplyStages, x.volume]);
    const availablePurchaseVolume = useMemo(
        () => supplyStages.reduce((volume, stage) => volume.sub(stage.volume), purchaseVolume),
        [supplyStages, purchaseVolume]
    );

    const selectAddress = async (address: Address | null, idx: number) => {
        // spread object for deep copy (vue moment)
        const result = await showModal(PickAddressModal, { existing: address ? { ...address } : undefined });
        if (result) {
            setSupplyStages(replaceOrPush(supplyStages, supplyStages[idx], {...supplyStages[idx], address: result}));
        }
    };


    const columns: Column<SupplyStage>[] = [
        Table.AutoColumn("Год окончания поставки", x => <>{x.item.type === "ABSOLUTE" ? x.item.endDate.getFullYear() : x.item.finishYear}</>),
        Table.Column("Дата начала поставки", x => <>{formatDate(x.item, false)}</>),
        Table.Column("Дата окончания поставки", x => <>{formatDate(x.item, true)}</>),
        Table.Column("Условия поставки", x => <>{x.item.conditions}</>),
        Table.Column("Адрес", x => <span style={{ textDecoration: "underline" }} onClick={suppressEvent(() => selectAddress(x.item.address, x.rowIndex))}>
            {applyN(formatAddress, x.item.address) ?? "Не указан"}
        </span>),
        Table.AutoColumn("Действие", a => {
            const [shown, setShown] = useState(false);
            if (x.readonly)
                return <></>;
            return <ContextMenuHolder shown={shown} hide={() => setShown(false)} items={[
                ["Редактировать", () => addOrEditSupplyStage(supplyStages, a.item.volume, a.item)],
                "sep",
                ["Удалить", () => removeSupplyStage(supplyStages, a.item)]]}>
                <Button title="действие" onClick={() => setShown(true)}/>
            </ContextMenuHolder>;
        })
    ];

    return <Card title="График поставки">
        <VStack spacing="10px">
            {
                !supplyStages.length
                    && <div>Поставок нет</div>
            }
            {
                supplyStages.length > 0 && <Table<SupplyStage> dataset={supplyStages} columns={
                    x.perItem
                        ? columns
                        : [Table.AutoColumn("Объём поставки", x => <>{x.item.volume.toString()}</>), ...columns]
                }/>
            }
            {
                !x.readonly &&
                    <Button color="green" title="добавить этап поставки" style={{alignSelf: "flex-start"}}
                            onClick={() => addOrEditSupplyStage(supplyStages, availablePurchaseVolume, undefined, x.perItem)}/>
            }

        </VStack>
    </Card>;
};
