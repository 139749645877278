import React from 'react';
import {Button, Error} from '@/components/primitive';
import {FormHeader} from "@/components/form/FormHeader";
import {Form} from "@/components/form/Form";
import {FormState} from "@/components/form";
import {
    InfoSection,
    PlannedPaymentsSection,
    PublicDiscussionSection,
    ProvisionSection,
    PurchasePerUnitSection,
    MinimalRequirementsSection
} from "../shared/sections";
import {
    actionToState,
    FormAction,
    formToTemplate, LotForm,
    SectionProps
} from "@/views/ComposedLot/shared/store";
import {createLot} from "@/api/ComposedLots";
import {Expandee, HStack, VStack} from "@/components/layouts";
import router from "@/router";
import {Store} from "effector";
import {LawType} from "@/models/enums";
import {SafeFnFactory, useErrorHandler} from "@/reactUtils";
import {useMappedStore} from "@/storeUtils";
import { JointBiddingSection } from "@/views/ComposedLot/shared/sections/JointBiddingSection";
import {spreadIf} from "@/miscUtils";
import {useStore} from "effector-react";
import { LifecycleConditionsSection } from "@/views/ComposedLot/shared/sections/LifecycleConditionsSection";

export const CreateLot: React.FC<{ lawType: LawType; storeContainer: { store: Store<LotForm> } }> = ({ lawType, storeContainer: { store } }) => {
    const [error, makeSafe] = useErrorHandler();
    const [isSmallVolume, isSingleProviderPurchase] = useMappedStore(store, s => [
        s.info.specialPurchaseType === "SMALL_VOLUME_SIMPLE" || s.info.specialPurchaseType === "SMALL_VOLUME_CARE",
        s.info.providerSelectionType === "SINGLE_PROVIDER_PURCHASE"
    ]);
    const lot = useStore(store);
    const [isMaster] = useMappedStore(store, s => [s.info.lotJoinType === "MASTER"]);

    const isSingleProviderPurchaseUnder600k = isSingleProviderPurchase && (lot.info.singleProviderReasonId === 1 || lot.info.singleProviderReasonId === 2);

    /*
    если создается лот обычный по единственному поставщику поля Обеспечения заявки/контракта/гарантии не отображаются
    -  в других лотах по закупке у единственного поставщика(не малый объем) данные поля должны отображаться.(c)
     */
    const isAggregated = isSingleProviderPurchase;

    const header = <VStack spacing="15px">
        <Error object={error}/>
        <FormHeader title="Новый лот"/>
    </VStack>;

    const dismiss = () => {
        router.replace("/plan-objects/composed-lots");
    };

    const save = makeSafe(async () => {
        const state = store.getState();
        const template = formToTemplate(state, true);

        const lot = await createLot(state.info.law, template);
        router.replace(`/plan-objects/composed-lots/${lot.id}`);
    });

    const navInset = <HStack>
        <Button icon="aSave" onClick={save} />
        <Expandee/>
        <Button color="red" icon="aClose" onClick={() => dismiss()}/>
    </HStack>;

    const action: FormAction = "creating";

    const sectionProps: SectionProps = {
        formStore: store,
        formAction: action,
        error,
        makeSafe: makeSafe as SafeFnFactory<void>
    };

    const is223 = lawType === "F223";
    const is44 = lawType === "F44";

    return <FormState value={actionToState(action)}>
        <Form header={header} navInset={navInset} tabs={[
            Form.Tab("info", "Общие сведения", [
                Form.Section("Общие сведения", <InfoSection {...sectionProps} isLotAggregated={isAggregated}/>),
                ...spreadIf(isMaster, Form.Section("Совместные торги", <JointBiddingSection {...sectionProps}/>)),
                ...spreadIf(!isSmallVolume, Form.Section("Закупка на единицу продукции", <PurchasePerUnitSection {...sectionProps}/>)),
                ...spreadIf(!isMaster, Form.Section("Планируемые платежи", <PlannedPaymentsSection {...sectionProps}/>)),
                ...spreadIf(!isSmallVolume && !!lot.info.contractLifecycle && is44, Form.Section("Условия контракта жизненного цикла", <LifecycleConditionsSection {...sectionProps} is223={is223}/>)),
                ...spreadIf(is44 && !isSmallVolume && !isMaster, Form.Section("Общественное обсуждение", <PublicDiscussionSection {...sectionProps}/>)),
                ...spreadIf(is223 && !isSmallVolume, Form.Section("Минимальные требования", <MinimalRequirementsSection {...sectionProps}/>)),
                ...spreadIf(!isSmallVolume && lot.info.singleProviderReasonId != 1 , Form.Section("Обеспечение заявки", <ProvisionSection {...sectionProps} kind="request" is223={is223}/>)),
                ...spreadIf(is44 && !isSmallVolume, Form.Section("Обеспечение контракта", <ProvisionSection {...sectionProps} kind="contract" is223={is223} isLifecycle={lot.info.contractLifecycle}/>)),
                ...spreadIf(is223 && !isSmallVolume && lot.info.singleProviderReasonId != 1, Form.Section("Обеспечение договора", <ProvisionSection {...sectionProps} kind="contract" is223={is223}/>)),
                ...spreadIf(!isSmallVolume && lot.info.singleProviderReasonId != 1, Form.Section("Требования к гарантии качества", <ProvisionSection {...sectionProps} kind="guarantee" is223={is223}/>))
            ])
        ]}/>
    </FormState>;
};
