export const mockReasons = [
    "Выполнение работ по проектированию и строительству аэродромов",
    "Выполнение работ по проектированию и строительству инфраструктуры морских и речных портов ",
    "Выполнение работ по проектированию и строительству инфраструктуры морских и речных портов, в том числе искусственных земельных участков, гидротехнических сооружений портов",
    "Выполнение работ по проектированию и строительству объектов инфраструктуры железнодорожного транспорта общего пользования",
    "Выполнение работ по проектированию и строительству объектов инфраструктуры метрополитена, внеуличного транспорта и городского наземного электрического транспорта",
    "Выполнение работ по проектированию и строительству объектов системы коммунальной инфраструктуры и иных объектов коммунального хозяйства, в том числе объектов водо-, тепло-, газо- и энергоснабжения, водоотведения, очистки сточных вод, переработки и утилизации (захоронения) бытовых отходов",
    "Выполнение работ по проектированию и строительству уникальных объектов капитального строительства",
    "Выполнение работ по проектированию, реконструкции с элементами реставрации и (или) приспособлению для современного использования (при необходимости) объектов культурного наследия (памятников истории и культуры) народов Российской Федерации, включая закупку оборудования, предусмотренного проектной документацией указанных объектов, в случае признания таких объектов аварийными в соответствии с требованиями законодательства Российской Федерации об объектах культурного наследия (о памятниках истории и культуры) народов Российской Федерации или нахождения таких объектов в руинированном состоянии",
    "Выполнение работ по проектированию, строительству и реконструкции объектов капитального строительства в сфере здравоохранения (в том числе объектов, предназначенных для санаторно-курортного лечения), включая закупку медицинского оборудования, предусмотренного проектной документацией указанных объектов капитального строительства",
    "Выполнение работ по проектированию, строительству и реконструкции объектов капитального строительства в сфере культуры (театров и амфитеатров, памятников и мемориальных сооружений, музеев, выставочных центров и выставочных комплексов, библиотек, цирков, кинотеатров, концертных залов), включая закупку оборудования, предусмотренного проектной документацией указанных объектов",
    "Выполнение работ по проектированию, строительству и реконструкции объектов, предназначенных для проживания военнослужащих и членов их семей, а также объектов хозяйственного, технического, тылового, медицинского назначения, учебно-материальной базы боевой подготовки, воспитательной работы и службы войск, включая закупку оборудования, предусмотренного проектной документацией указанных объектов",
    "Выполнение работ по проектированию, строительству и эксплуатации объектов капитального строительства, предназначенных для социального обслуживания граждан, в том числе для стационарного и полустационарного социального обслуживания граждан (домов-интернатов (пансионатов) для детей, престарелых, инвалидов, ветеранов войны, труда и милосердия, психоневрологических интернатов, социально-оздоровительных центров, геронтологических центров, геронтопсихиатрических центров, социально-реабилитационных центров, реабилитационных центров, кризисных центров помощи женщинам, центров психолого-педагогической помощи населению, центров социального обслуживания населения, центров социальной адаптации (помощи), домов ночного пребывания, социальных приютов, социальных гостиниц), включая закупку оборудования, предусмотренного проектной документацией указанных объектов",
    "Выполнение работ по созданию автоматизированных информационно-измерительных систем учета энергетических и коммунальных ресурсов (включая работы по установке приборов (узлов) учета, устройств сбора и передачи данных учета, а также по созданию программных продуктов для сбора, хранения и передачи данных учета).",
    "Выполнение работ по созданию, вводу в эксплуатацию, эксплуатации и выводу из эксплуатации информационных систем, центров обработки данных и программно-аппаратных комплексов",
    "Выполнение работ по строительству (реконструкции, капитальному ремонту и при необходимости по инженерным изысканиям и (или) проектированию) и содержанию автомобильных дорог (участков автомобильных дорог), включая дорожные сооружения, являющиеся их технологической частью, - защитные дорожные сооружения, искусственные дорожные сооружения, производственные объекты, элементы обустройства автомобильных дорог, и (или) работ по их ремонту",
    "Закупка в соответствии с п. 47 ч. 1 ст. 93 Закона № 44-ФЗ товара, производство которого создано или модернизировано и (или) освоено на территории Российской Федерации в соответствии со специальным инвестиционным контрактом, у организации оборонно-промышленного комплекса, включенной в сводный реестр организаций оборонно-промышленного комплекса, формируемый в соответствии с ПП РФ от 20 февраля 2004 г. N 96 «О сводном реестре организаций оборонно-промышленного комплекса», являющейся стороной - инвестором указанного специального инвестиционного контракта",
    "Закупка воздушных судов, морских и речных судов",
    "Закупка железнодорожного подвижного состава, транспортных средств метрополитена, внеуличного транспорта и городского наземного электрического транспорта, самоходных машин и других видов техники",
    "Закупка медицинской техники, включенной в коды 26.60.11, 26.60.12, 26.60.13.130, 26.70.22.150, 32.50.12.000, 32.50.21.121, 32.50.21.122 (ОКПД2) ОК 034-2014"
];